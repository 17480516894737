import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from '../http';
import { activeSnack } from '../store/common';

export const getAllState = createAsyncThunk(
  'state/list',
  async ({ page, search, limit, dispatch, countryId }) => {
    try {
      const response = await get(
        `/state/all?country=${countryId && countryId}&page=${page && page}&search=${
          search && search
        }&limit=${limit && limit}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllDistrict = createAsyncThunk(
  'district/list',
  async ({ page, search, limit, dispatch, stateId }) => {
    try {
      const response = await get(
        `/district/all?state=${stateId && stateId}&page=${page && page}&search=${
          search && search
        }&limit=${limit && limit}`
      );
      console.log(response);
      if (response) {
        return {
          // response,
          districts: response?.districts,
          total: response?.total,
          page: response?.page,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
