import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { CurrencySwitch } from 'src/hooks/use-currency';

import eye from 'src/assets/other/eye.png';
import cart from 'src/assets/other/cart.png';
import person from 'src/assets/other/person.png';
// import product from 'src/assets/other/product.png';

// ----------------------------------------------------------------------

const SPACING = 3;

export default function OverviewAppView() {
  const theme = useTheme();
  const currency = CurrencySwitch();

  console.log(theme?.palette?.mode);
  const { dashboardData } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    loading: state.dashboard.loading,
  }));

  console.log(dashboardData);
  // const icon = (name, IconColor) => (
  //   <SvgColor
  //     src={`/assets/icons/navbar/${name}.svg`}
  //     sx={{ width: 1, height: 1, color: IconColor }}
  //   />
  // );
  console.log(currency);

  return (
    <Grid container spacing={SPACING} disableEqualOverflow marginBottom="2rem">
      <Grid item xs={12} md={4} spacing={SPACING}>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          gap={3}
          spacing={SPACING}
        >
          <Link style={{ textDecoration: 'none', width: '100%' }} md={6}>
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.todayPartnersCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Partners <br /> Count Today{' '}
              </Typography>
            </Card>
          </Link>
          <Link
            to={paths.dashboard.share_holders}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.todayShareholdersCount || 0}
              </Typography>
              <Typography className="color-card-title">
                {' '}
                Share holders <br /> Count Today{' '}
              </Typography>
            </Card>
          </Link>
        </Grid>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          gap={3}
          spacing={SPACING}
        >
          <Link
            to={paths.dashboard.transactions}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.todayTransactionsCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Transactions <br />
                Count Today
              </Typography>
            </Card>
          </Link>
          <Link style={{ textDecoration: 'none', width: '100%' }} md={6}>
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalSharesApprovedCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Shares <br />
                Approved
              </Typography>
            </Card>
          </Link>
        </Grid>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          gap={3}
          spacing={SPACING}
        >
          <Link style={{ textDecoration: 'none', width: '100%' }} md={6}>
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalSharesPendingCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                {' '}
                Shares <br />
                Pending{' '}
              </Typography>
            </Card>
          </Link>
          <Link
            to={paths.dashboard.transactions}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalTransactionsCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Total <br />
                Transactions{' '}
              </Typography>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} spacing={SPACING}>
        {/* <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}> */}
        <Card className="dashboard-cards-new">
          <Typography className="card-title">Total Purchased Shares</Typography>
          <Typography className="card-count">
          ₹ {dashboardData?.totalPurchasedSharesAmount || 0}
          </Typography>
        </Card>
        {/* </Link> */}
        {/* <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}> */}
        <Card className="dashboard-cards-new">
          <Typography className="card-title">Total Shares Approved</Typography>
          <Typography className="card-count">
           {dashboardData?.totalSharesApprovedAmount || 0}
          </Typography>
        </Card>
        {/* </Link> */}
        {/* <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}> */}
        <Card className="dashboard-cards-new">
          <Typography className="card-title">Total Shares Pending</Typography>
          <Typography className="card-count">
          ₹{dashboardData?.totalSharesPendingAmount || 0}
          </Typography>
        </Card>
        {/* </Link> */}
      </Grid>

      <Grid item xs={12} md={4} spacing={SPACING}>
        {/* <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}> */}
        <Card
          className="dashboard-cards-new colored-dashboard-card"
          sx={{
            backgroundColor: '#333333',
          }}
        >
          <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
            <Typography className="color-card-count">
              {dashboardData?.totalPartnersCount || 0}
            </Typography>
            <Typography className="color-card-title">Total Partners </Typography>
          </Stack>
          <img src={person} alt="users" className="color-card-img" />
        </Card>
        {/* </Link> */}
        <Link to={paths.dashboard.share_phases} style={{ textDecoration: 'none' }}>
          <Card
            className="dashboard-cards-new colored-dashboard-card"
            sx={{
              backgroundColor: '#007867',
            }}
          >
            <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
              <Typography className="color-card-count">
                {dashboardData?.totalSharePhasesCount || 0}
              </Typography>
              <Typography className="color-card-title">Total Share Phases </Typography>
            </Stack>
            <img src={cart} alt="sellers" className="color-card-img" />
          </Card>
        </Link>
        <Link to={paths.dashboard.share_holders} style={{ textDecoration: 'none' }}>
          <Card
            className="dashboard-cards-new colored-dashboard-card"
            sx={{
              backgroundColor: '#FFAB00',
            }}
          >
            <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
              <Typography className="color-card-count">
                {dashboardData?.totalShareholdersCount}
              </Typography>
              <Typography className="color-card-title" sx={{ color: '#212B36' }}>
                Total Share holders
              </Typography>
            </Stack>
            <img
              src={eye}
              alt="view"
              className="color-card-img"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}
            />
          </Card>
        </Link>
      </Grid>
    </Grid>
  );
}

OverviewAppView.propTypes = {};
