import propType from 'prop-types';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function PaymentOptional({ watch, activeStep }) {
  const { buyShareDetails } = useSelector((state) => ({
    buyShareDetails: state.share.buyShare,
  }));

  return (
    <>
      {activeStep < 2 && (
        <Stack spacing={1} sx={{ backgroundColor: 'white', mt: 1 }}>
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#6B6B6B',
              justifyContent: 'start',
            }}
          >
            Easily make payments using our secure methods: QR code or bank transfer. Choose the
            option that suits you best
          </Typography>
        </Stack>
      )}
      {activeStep > 2 && (
        <Stack spacing={1} sx={{ backgroundColor: 'white', mt: 1 }}>
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#6B6B6B',
              justifyContent: 'start',
            }}
          >
            <span style={{ color: '#0DD45D' }}>Paid</span>&nbsp;&nbsp;
            <Typography variant="h5" sx={{ color: '#145072' }}>
              ₹ {buyShareDetails?.total_amount}
            </Typography>
            &nbsp;&nbsp; ({buyShareDetails?.share_amount}x{buyShareDetails?.share_count})
          </Typography>
        </Stack>
      )}
    </>
  );
}

PaymentOptional.propTypes = {
  watch: propType.any,
  activeStep: propType.number,
};
