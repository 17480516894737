import * as React from 'react';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import UpdateSettingsData from 'src/sections/settings/settings';

// components

export default function Settings() {
  return (
    <div>
      <MetaHelmet title="Settings" />
      <UpdateSettingsData />
    </div>
  );
}
