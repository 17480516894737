import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import { paths } from 'src/routes/routes/paths';

import { _userAbout, _userFeeds } from 'src/_mock';
import { getUserDetails } from 'src/server/api/users';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import ProfileHome from 'src/sections/partner/profile-home';
import ProfileCover from 'src/sections/partner/profile-cover';

import PartnerDetailsPage from './PartnerUpdatePage';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },

  {
    value: 'update',
    label: 'Update Details',
    icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
  },
];

// ----------------------------------------------------------------------

export default function PartnerProfileView() {
  const settings = useSettingsContext();
  const params = useParams();
  const dispatch = useDispatch();
  const userId = params.id;

  const { userDetails } = useSelector((state) => ({
    userDetails: state.users.userDetail,
  }));
  console.log(userDetails);

  const [currentTab, setCurrentTab] = useState('profile');
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    const credentials = {
      userId,
      dispatch,
    };
    console.log(credentials);
    dispatch(getUserDetails(credentials));
  }, [dispatch, userId]);

  const role = userDetails?.role;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="User Details"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'Users', href: paths.dashboard.partner },
          { name: userDetails?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Card
        sx={{
          mb: 3,
          height: 170,
        }}
      >
        <ProfileCover
          role={role}
          name={userDetails?.name}
          avatarUrl=""
          coverUrl={_userAbout.coverUrl}
        />

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>

      {currentTab === 'profile' && <ProfileHome info={_userAbout} posts={_userFeeds} />}
      {currentTab === 'update' && <PartnerDetailsPage />}
    </Container>
  );
}
