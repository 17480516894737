import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import {  Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { fDate } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ProfileHome({ info, posts }) {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.users.userDetail,

  }));

  console.log(userDetails);

  // const handleAttach = () => {
  //   if (fileRef.current) {
  //     fileRef.current.click();
  //   }
  // };

  // const renderFollows = (
  //   <Card sx={{ py: 3, textAlign: 'center', typography: 'h4' }}>
  //     <Stack
  //       direction="row"
  //       divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
  //     >
  //       <Stack width={1} sx={{ textTransform: 'capitalize' }}>
  //         {userDetails?.head_partner?.name}
  //         <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
  //           Head Partner
  //         </Box>
  //       </Stack>

  //       <Stack width={1}>
  //         {userDetails?.partner_code}
  //         <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
  //           Partner Code
  //         </Box>
  //       </Stack>
  //     </Stack>
  //   </Card>
  // );

  console.log(userDetails);
  const renderAbout = (
    <Card>
      <Stack
        sx={{ p: 2, pb: 0 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">About</Typography>
      </Stack>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Box sx={{ typography: 'body2', maxWidth: 500 }}>
          {userDetails?.name} is a {userDetails?.type?.replace('_', ' ')} partner under{' '}
          {userDetails?.head_partner?.name}, the head partner, and he joined on{' '}
          {fDate(userDetails?.createdAt, 'dd/mm/yyyy')}.
        </Box>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Iconify icon="mingcute:location-fill" width={24} />

          <Box sx={{ typography: 'body2' }}>
            {`Live at `}
            <Link variant="subtitle2" color="inherit">
              {userDetails?.country?.name} 
            </Link>
          </Box>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
          <Iconify icon="fluent:phone-24-filled" width={24} sx={{ mr: 2 }} />
          {userDetails?.phone}
        </Stack>

        {/* <Stack direction="row" alignItems="center" spacing={2}>
          <Iconify icon="ic:round-business-center" width={24} />
          <Tooltip title="Qualification">
            <Box sx={{ typography: 'body2' }}>{userDetails.qualification}</Box>
          </Tooltip>
        </Stack> */}
      </Stack>
    </Card>
  );

  // const renderPostInput = (
  //   <Card sx={{ p: 3 }}>
  //     <InputBase
  //       multiline
  //       fullWidth
  //       rows={4}
  //       placeholder="Share what you are thinking here..."
  //       sx={{
  //         p: 2,
  //         mb: 3,
  //         borderRadius: 1,
  //         border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
  //       }}
  //     />

  //     <Stack direction="row" alignItems="center" justifyContent="space-between">
  //       <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'text.secondary' }}>
  //         <Fab size="small" color="inherit" variant="softExtended" onClick={handleAttach}>
  //           <Iconify icon="solar:gallery-wide-bold" width={24} sx={{ color: 'success.main' }} />
  //           Image/Video
  //         </Fab>

  //         <Fab size="small" color="inherit" variant="softExtended">
  //           <Iconify icon="solar:videocamera-record-bold" width={24} sx={{ color: 'error.main' }} />
  //           Streaming
  //         </Fab>
  //       </Stack>

  //       <Button variant="contained">Post</Button>
  //     </Stack>

  //     <input ref={fileRef} type="file" style={{ display: 'none' }} />
  //   </Card>
  // );

  const address = userDetails?.address;

  const renderSocials = (
    <Card>
      <CardHeader title="Address" />

      <Stack spacing={2} sx={{ p: 3, pt: 0 }}>
        <Stack spacing={0} direction="column" sx={{ wordBreak: 'break-all', typography: 'body2' }}>
          <Typography variant="body2">{address?.address_line1}</Typography>
          <Typography variant="body2">{address?.address_line2}</Typography>
          <Typography variant="body2">{address?.pincode}</Typography>
        </Stack>
        <Stack spacing={2} direction="row" sx={{ wordBreak: 'break-all', typography: 'body2' }}>
          <LocationOnIcon />
          Sate : {address?.state?.name}
        </Stack>
        <Stack spacing={2} direction="row" sx={{ wordBreak: 'break-all', typography: 'body2' }}>
          <LocationOnIcon />
          District : {address?.district?.name}
        </Stack>
      </Stack>
    </Card>
  );

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={3}>

          {renderSocials}
        </Stack>
      </Grid>

      <Grid xs={12} md={8}>
        <Stack spacing={3}>{renderAbout}</Stack>
      </Grid>
    </Grid>
  );
}

ProfileHome.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};
