import propType from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, TextField, Autocomplete } from '@mui/material';

import { getAllCountries } from 'src/server/api/country';
import { getAllState, getAllDistrict } from 'src/server/api/address';

import { RHFTextField } from 'src/components/hook-form';
// ----------------------------------------------------------------------

export default function PartnerDetailForm({ maxWidth, lsgData }) {
  const dispatch = useDispatch();

  const { countryList, stateList, districtList, currentShareDetails } = useSelector((state) => ({
    countryList: state.country.country,
    stateList: state.address.state,
    districtList: state.address.district,
    currentShareDetails: state.share.buyShare,
  }));

  const {
    selectedCountry,
    setSelectedCountry,
    selectedState,
    setSelectedState,
    setSelectedDistrict,
  } = lsgData;

  const renderHead = (
    <Stack spacing={0.5} sx={{ mb: 2 }}>
      <Typography variant="body2" sx={{ color: '#9EA0A3' }}>
        Fill in all the information in the form below.
      </Typography>
      <Typography variant="caption" sx={{ color: '#9EA0A3' }}>
        Please make sure to complete all the fields marked with an asterisk ({' '}
        <span style={{ color: 'red' }}> * </span>)
      </Typography>
    </Stack>
  );

  const renderForm = (
    <>
      <Typography variant="body2" mb={2} color="#01B0DC">
        Personal Details :
      </Typography>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          sx={{ color: '#145072' }}
          name="name"
          label="Name"
          placeholder="Enter name"
          type="text"
          required
        />

        <RHFTextField
          size="small"
          sx={{ color: '#145072' }}
          name="surname"
          label="Surname"
          placeholder="Enter surname"
          type="text"
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          name="date_of_birth"
          label="Date of birth"
          type="date"
          InputLabelProps={{ shrink: true }}
          required
        />
        <RHFTextField
          size="small"
          name="father_name"
          label="Name of father"
          placeholder="Enter your father name"
          type="text"
          required
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          required
          name="phone"
          placeholder="Enter phone number"
          label="Mobile number"
          type="number"
        />
        <RHFTextField
          size="small"
          name="whatsapp_number"
          label="WhatsApp number"
          placeholder="Enter whatsApp number"
          type="number"
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          // required
          name="email"
          label="Email"
          placeholder="Enter your email address"
          type="email"
        />
      </Stack>

      <Typography variant="body2" mb={2} mt={1.5} color="#01B0DC">
        Address :
      </Typography>

      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          required
          placeholder="Enter your address"
          label="Address line 1"
          name="address.addressLine1"
          type="text"
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          placeholder="Enter your address"
          name="address.addressLine2"
          label="Address Line2"
          type="text"
        />
        <RHFTextField
          size="small"
          placeholder="Post office"
          name="address.post_office"
          required
          label="Post office"
          type="text"
        />
      </Stack>
    </>
  );

  const defaultCountry = useMemo(
    () => ({
      label: currentShareDetails?.address?.country?.name,
      value: currentShareDetails?.address?.country?._id,
    }),
    [currentShareDetails?.address?.country]
  );

  const defaultState = useMemo(
    () => ({
      label: currentShareDetails?.address?.state?.name,
      value: currentShareDetails?.address?.state?._id,
    }),
    [currentShareDetails?.address?.state]
  );

  const defaultDistrict = useMemo(
    () => ({
      label: currentShareDetails?.address?.district?.name,
      value: currentShareDetails?.address?.district?._id,
    }),
    [currentShareDetails?.address?.district]
  );

  // country
  const [searchValue, setSearchValue] = useState('');
  // state
  const [searchValueState, setSearchValueSate] = useState('');
  // district
  const [searchValueDistrict, setSearchValueDistrict] = useState('');

  const optionCountry = [
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValue,
      dispatch,
      limit: 10,
    };
    // console.log('Credentials:', credentials);
    dispatch(getAllCountries(credentials));
  }, [dispatch, searchValue]);

  const handleAutocompleteChange = (_, newValue) => {
    setSelectedCountry(newValue?.value || '');
  };
  const handleTextFieldChange = (event) => {
    setSearchValue(event.target.value);
  };

  // state
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueState,
      limit: 10,
      dispatch,
      countryId: selectedCountry,
    };
    // console.log('Credentials:', credentials);
    dispatch(getAllState(credentials));
  }, [dispatch, searchValueState, selectedCountry]);

  const handleAutocompleteChangeState = (_, newValue) => {
    setSelectedState(newValue?.value || '');
  };
  const handleTextFieldChangeState = (event) => {
    setSearchValueSate(event.target.value);
  };
  const optionState = [
    ...(stateList?.states || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];
  // district
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueDistrict,
      limit: 10,
      dispatch,
      stateId: selectedState,
    };
    dispatch(getAllDistrict(credentials));
  }, [dispatch, searchValueDistrict, selectedState]);

  const handleAutocompleteChangeDistrict = (_, newValue) => {
    setSelectedDistrict(newValue?.value || '');
  };
  const handleTextFieldChangeDistrict = (event) => {
    setSearchValueDistrict(event.target.value);
  };

  const optionDistrict = [
    ...(districtList?.districts || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  console.log(defaultCountry);

  const lsgRender = (
    <>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          required
          placeholder="Enter pincode"
          name="address.pincode"
          label="Pincode"
          type="number"
        />

        <Autocomplete
          id="country-select-demo"
          sx={{ width: '100%' }}
          options={optionCountry}
          autoHighlight
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChange}
          defaultValue={
            defaultCountry?.value !== undefined
              ? defaultCountry
              : {
                  label: 'select country...',
                  value: '',
                }
          }
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              required
              name="country"
              size="small"
              value={selectedCountry}
              onChange={handleTextFieldChange}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      </Stack>
      <Stack sx={{ flexDirection: 'row' }} spacing={2} maxWidth={maxWidth}>
        {selectedCountry && (
          <Autocomplete
            id="state-select-demo"
            sx={{ width: '50%' }}
            options={optionState}
            autoHighlight
            defaultValue={
              defaultState?.value !== undefined
                ? defaultState
                : {
                    label: 'select state...',
                    value: '',
                  }
            }
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeState}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                required
                size="small"
                name="address.state"
                onChange={handleTextFieldChangeState}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        )}
        {selectedState && (
          <Autocomplete
            id="district-select-demo"
            sx={{ width: '50%' }}
            options={optionDistrict}
            defaultValue={
              defaultDistrict?.value !== undefined
                ? defaultState
                : {
                    label: 'select district...',
                    value: '',
                  }
            }
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeDistrict}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="District"
                size="small"
                name="address.district"
                onChange={handleTextFieldChangeDistrict}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        )}
      </Stack>
    </>
  );

  const nomineeRender = (
    <>
      <Typography variant="body2" mb={2} mt={3} color="#01B0DC">
        Nominee Details :
      </Typography>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          name="nominee_details.first_name"
          label="First name"
          placeholder="Enter your first name"
          type="text"
        />
        <RHFTextField
          size="small"
          name="nominee_details.last_name"
          label="Last name"
          placeholder="Enter your last name"
          type="text"
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          placeholder="Enter relation"
          name="nominee_details.relation"
          label="Relation"
          type="text"
          inputProps={{ inputMode: 'text' }}
        />
      </Stack>
    </>
  );

  const bankDetailsRender = (
    <>
      <Typography variant="body2" mb={2} mt={2} color="#01B0DC">
        Bank Details :
      </Typography>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          name="bank_details.bank_name"
          label="Bank name"
          placeholder="Enter bank name"
          type="text"
        />
        <RHFTextField
          size="small"
          name="bank_details.branch_name"
          label="Branch name"
          placeholder="Enter Branch name"
          type="text"
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          placeholder="Enter account number"
          name="bank_details.account_number"
          label="Account number"
          type="text"
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          placeholder="Enter IFSC"
          name="bank_details.ifsc_code"
          label="IFSC code"
          type="text"
        />
      </Stack>
    </>
  );

  const idProofRender = (
    <>
      <Typography variant="body2" mb={2} mt={2} color="#01B0DC">
        ID Proof Details :
      </Typography>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          name="id_documents.aadhaar_number"
          label="Aadhaar number"
          placeholder="Enter aadhaar number"
          type="number"
          required
        />
      </Stack>
      <Stack spacing={2} direction="row" maxWidth={maxWidth} mb={2}>
        <RHFTextField
          size="small"
          name="id_documents.voter_id"
          label="Voter id"
          placeholder="Enter voter id"
          type="text"
        />
      </Stack>
    </>
  );

  return (
    <Stack sx={{ backgroundColor: 'white', borderRadius: '10px', marginTop: 2 }}>
      {renderHead}
      {renderForm}
      {lsgRender}
      {nomineeRender}
      {bankDetailsRender}
      {idProofRender}
    </Stack>
  );
}

PartnerDetailForm.propTypes = {
  maxWidth: propType.number,
  lsgData: propType.number,
};
