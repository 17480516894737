import Container from '@mui/material/Container';

import { paths } from 'src/routes/routes/paths';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import AccountOverView from '../profile/account-overview';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ProfileTransaction() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Account"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'User', href: paths.dashboard.root },
          { name: 'Account' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <AccountOverView type="transaction" />
    </Container>
  );
}
