import { createSlice } from '@reduxjs/toolkit';

import {
  loginApi,
  logoutApi,
  registerApi,
  verifyOtpApi,
  getUserProfile,
  partnerLoginApi,
  updateUserProfile,
} from '../api/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    loginLoading: false,
    error: {},
    user: {
      email: '',
      username: '',
      isActive: false,
    },
    verify: {},
  },
  reducers: {},
  extraReducers: {
    // login
    [loginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [loginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [loginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // login partner
    [partnerLoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [partnerLoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [partnerLoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // register
    [registerApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [registerApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.verify = action.payload;
      state.error = {};
    },
    [registerApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // verify
    [verifyOtpApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [verifyOtpApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.verify = action.payload;
      state.error = {};
    },
    [verifyOtpApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // profile
    [getUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [getUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update profile
    [updateUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [updateUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // logout
    [logoutApi.fulfilled]: (state) => {
      state.user = {};
      state.error = {};
    },
    [logoutApi.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});
// export const {} = authSlice.actions;

export default authSlice.reducer;
