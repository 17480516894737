import { createSlice } from '@reduxjs/toolkit';

import {
  getAllShares,
  getAllSharePhase,
  getOneShareDetails,
  getAllTransactions,
  checkShareCountLimit,
  getAllPurchasedShares,
  uploadPaymentScreenshot,
  updateTransactionDetails,
  getTransactionDetailsByShareholder,
} from '../api/shares';

export const ShareSlice = createSlice({
  name: 'shares',
  initialState: {
    loading: false,
    error: {},
    shares: {},
    sharePhase: {},
    buyShare: {},
    screenshotUpload: {},

    transaction: null,
    myShares: {},
    shareCountLimit: {},
    allTransactions: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all shares
    [getAllShares.pending]: (state) => {
      state.loading = true;
    },
    [getAllShares.fulfilled]: (state, action) => {
      state.loading = false;
      state.shares = action.payload;
      state.error = {};
    },
    [getAllShares.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllSharePhase.pending]: (state) => {
      state.loading = true;
    },
    [getAllSharePhase.fulfilled]: (state, action) => {
      state.loading = false;
      state.sharePhase = action.payload;
      state.error = {};
    },
    [getAllSharePhase.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllPurchasedShares.pending]: (state) => {
      state.loading = true;
    },
    [getAllPurchasedShares.fulfilled]: (state, action) => {
      state.loading = false;
      state.myShares = action.payload;
      state.error = {};
    },
    [getAllPurchasedShares.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getOneShareDetails.pending]: (state) => {
      state.loading = true;
    },
    [getOneShareDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.buyShare = action.payload;
      state.error = {};
    },
    [getOneShareDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [uploadPaymentScreenshot.pending]: (state) => {
      state.loading = true;
    },
    [uploadPaymentScreenshot.fulfilled]: (state, action) => {
      state.loading = false;
      state.screenshotUpload = action.payload;
      state.error = {};
    },
    [uploadPaymentScreenshot.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getTransactionDetailsByShareholder.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionDetailsByShareholder.fulfilled]: (state, action) => {
      state.loading = false;
      state.transaction = action.payload;
      state.error = {};
    },
    [getTransactionDetailsByShareholder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [checkShareCountLimit.pending]: (state) => {
      state.loading = true;
    },
    [checkShareCountLimit.fulfilled]: (state, action) => {
      state.loading = false;
      state.shareCountLimit = action.payload;
      state.error = {};
    },
    [checkShareCountLimit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTransactions = action.payload;
      state.error = {};
    },
    [getAllTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateTransactionDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateTransactionDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.transaction = action.payload;
    //   state.error = {};
    // },
    [updateTransactionDetails.fulfilled]: (state, action) => {
      // const jsonState = covertToJSON(state)?.transaction;
      // const modifiedProductList = {
      //   ...jsonState,
      //   transaction: jsonState.transaction?.map((i) =>
      //     i._id === action.payload._id ? action?.payload : i
      //   ),
      // };

      state.loading = false;
      // state.marketPlaceDetails = action.payload;
      state.transaction = action.payload;
      state.error = {};
    },
    [updateTransactionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = ShareSlice.actions;

export default ShareSlice.reducer;
