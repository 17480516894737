import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import RegisterOtpView from 'src/sections/auth/register-otp-view';

// ----------------------------------------------------------------------

export default function VerifyOtpPage() {
  return (
    <>
      <MetaHelmet title="Register" />

      <RegisterOtpView />
    </>
  );
}
