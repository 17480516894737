import { useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Chip, Tooltip, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';

import { useResponsive } from 'src/hooks/use-responsive';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';

// ----------------------------------------------------------------------

// const TABLE_DATA = [
//   {
//     phase: 'Phase 1',
//     shares: '300',
//     amount: '₹25,000',
//   },
//   {
//     phase: 'Phase 2',
//     shares: '300',
//     amount: '₹27,500',
//   },
//   {
//     phase: 'Phase 3',
//     shares: '300',
//     amount: '₹30,000',
//   },
//   {
//     phase: 'Phase 4',
//     shares: '300',
//     amount: '₹32,500',
//   },
// ];

const TABLE_HEAD = [
  { id: 'phase', label: 'Phase', align: 'start' },
  { id: 'shares', label: 'no.of shares', align: 'start' },
  { id: 'amount', label: 'share Amount', align: 'start' },
];

// ----------------------------------------------------------------------

export default function ShareTable() {
  const { sharePhase } = useSelector((state) => ({
    sharePhase: state.share.sharePhase,
  }));
  const mobile = useResponsive('down', 'md');

  const handleCheckActive = (row) => {
    if (row?.is_active) {
      return `Current Active Phase, only ${row?.balance_share} left`;
    }
    return '';
  };

  return (
    <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
      <Scrollbar>
        <Table sx={{ minWidth: 200 }} className="my_share_table">
          <TableHeadCustom
            headLabel={TABLE_HEAD}
            sx={{
              borderBottom: '1px solid #EBEAED',
              backgroundColor: 'red',
            }}
          />
          <TableBody>
            {sharePhase?.sharePhases?.map((row) => (
              <TableRow
                key={row?.phase}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  borderBottom: '1px solid #EBEAED',
                }}
              >
                <TableCell align="start">
                  <Tooltip title={handleCheckActive(row)}>
                    <Typography
                      variant="body2"
                      style={{
                        color: row?.is_completed ? 'gray' : '#145072',
                        fontWeight: 500,
                        display: 'flex',
                      }}
                    >
                      {row?.phase}{' '}
                      {row?.is_active && row?.balance_share <= 300 && (
                        <Chip
                          variant="outlined"
                          color="success"
                          size="small"
                          sx={{ fontSize: '12px', mx: 1 }}
                          label={`${row?.balance_share} shares left`}
                        />
                      )}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="start">
                  <Tooltip title={handleCheckActive(row)}>
                    <Typography
                      variant="body2"
                      style={{ color: row?.is_completed ? 'gray' : '#145072', fontWeight: 500 }}
                    >
                      {row?.total_share_count}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="start">
                  <Tooltip title={handleCheckActive(row)}>
                    <Typography
                      variant="body2"
                      style={{ color: row?.is_completed ? 'gray' : '#145072', fontWeight: 500 }}
                    >
                      {mobile ? '' : '₹ '}
                      {row?.single_share_amount}
                    </Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );
}
