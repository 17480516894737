// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login/admin`,
    login_partner: `${ROOTS.AUTH}/login/partner`,
    login_school: `${ROOTS.AUTH}/login/school`,

    register: `${ROOTS.AUTH}/register`,
    verify: `${ROOTS.AUTH}/verify`,

    set_role: `${ROOTS.AUTH}/select-user-role`,

    logout: `${ROOTS.AUTH}/logout`,

    partner_registration: `${ROOTS.AUTH}/partner-registration`,
    school_registration: `${ROOTS.AUTH}/school-registration`,

    create_password: (type, token) => `${ROOTS.AUTH}/create-password/${type}/${token}`,
    forgot_password_email: (type, token) => `${ROOTS.AUTH}/password/reset/${type}/${token}`,
    forgot_password: `${ROOTS.AUTH}/forgot-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    profileTransaction: (id) => `${ROOTS.DASHBOARD}/profile/transaction/${id}`,

    // country: `${ROOTS.DASHBOARD}/country`,

    users: `${ROOTS?.DASHBOARD}/users`,
    settings: `${ROOTS?.DASHBOARD}/settings`,
    user_view: (id) => `${ROOTS.DASHBOARD}/user/${id}`,
    // partner_type: `${ROOTS.DASHBOARD}/partner-type`,
    // admin new
    transaction: `${ROOTS.DASHBOARD}/transaction`,
    transactions: `${ROOTS.DASHBOARD}/transactions`,
    transaction_view: (id) => `${ROOTS.DASHBOARD}/transactions/${id}`,

    // labModel: `${ROOTS.DASHBOARD}/labModel`,

    share_holders: `${ROOTS.DASHBOARD}/share-holders`,
    share_holder_view: (id) => `${ROOTS.DASHBOARD}/share-holders/${id}`,

    share_phases: `${ROOTS.DASHBOARD}/share-phases`,
    share_phase_view: (id) => `${ROOTS.DASHBOARD}/share-phase/${id}`,

    // sale_items: `${ROOTS.DASHBOARD}/sale-items`,
    // partnership_schools: `${ROOTS.DASHBOARD}/partnership-schools`,
    // partnership_schools_view: (id) => `${ROOTS.DASHBOARD}/partnership-schools/${id}`,
    // partner_commission: `${ROOTS.DASHBOARD}/partner-commission`,

    // school_user: `${ROOTS.DASHBOARD}/schoolUser`,
    // school_user_update: `${ROOTS.DASHBOARD}/school/update`,

    // class: `${ROOTS.DASHBOARD}/class`,
    // exams: `${ROOTS.DASHBOARD}/exam`,
    // students: `${ROOTS.DASHBOARD}/students`,
    // certificates: `${ROOTS.DASHBOARD}/certificates`,
    // course_orders: `${ROOTS.DASHBOARD}/course-orders`,

    // course: `${ROOTS.DASHBOARD}/course`,
    // course_view: (id) => `${ROOTS.DASHBOARD}/course/${id}`,

    // kit: `${ROOTS.DASHBOARD}/kit`,

    // earnings: `${ROOTS.DASHBOARD}/earnings`,
  },
};
