import React from 'react';
import propTypes from 'prop-types';

import { Box, Button, Container } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import InvoiceDetails from './invoice/view/invoice-details';

DownloadInvoice.propTypes = {
  handleBack: propTypes.any,
  isMinimal: propTypes.bool,
  shareCertificate:propTypes.any,
};

function DownloadInvoice({ handleBack, isMinimal ,shareCertificate}) {
  return (
    <div>
      <Container maxWidth={false} className="zero-padding-container">
        <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-start' }}>
          {!isMinimal && (
            <Button
              size="medium"
              variant="outlined"
              onClick={handleBack}
              sx={{ color: '#145072', pr: 2.5, mr: 2, py: 0.9, borderRadius: '30px' }}
            >
              <ArrowBackIosNewRoundedIcon fontSize="small" sx={{ height: '12px' }} /> Previous
            </Button>
          )}
          <InvoiceDetails shareCertificate={shareCertificate}/>
        </Box>
      </Container>{' '}
      {/* <Button
        variant="contained"
        color="info"
        sx={{ borderRadius: '30px', my: 2, backgroundColor: '#145072', maxWidth: '200px' }}
        size="medium"
      >
        <Image
          alt="doc"
          src="/assets/images/about/cloud.png"
          ratio="1/1"
          sx={{
            width: 20,
            height: 20,
            objectFit: 'contain',
            mr: 1,
          }}
        />
        Download Invoice
      </Button> */}
    </div>
  );
}

export default DownloadInvoice;
