import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

import Iconify from 'src/components/iconify';

import InvoicePDF from './invoice-pdf';

// ----------------------------------------------------------------------

export default function InvoiceToolbar({ invoice }) {
  const { shareHolderDetails } = useSelector((state) => ({
    shareHolderDetails: state.share.buyShare,
    shareHolders: state.share.myShares.shareHolders,
  }));
  console.log(invoice);
  console.log(shareHolderDetails);

  console.log(invoice?.shareCertificate?.url);
  console.log(shareHolderDetails?.share_certificate?.url);
  // const handleDownload = () => {
  //   if (!invoice?.shareCertificate?.url) {
  //     return; // Handle the case where the URL is not available
  //   }

  //   const pdfUrl = invoice.shareCertificate.url;

  //   // Option 1: Using `window.open` (more control over download behavior)
  //   const link = document.createElement('a');
  //   link.href = pdfUrl;
  //   link.download = 'my-report.pdf';
  //   link.target = '_blank'; // Open in a new tab/window
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);

  //   // Option 2: Using `fetch` (potential browser compatibility issues)
  //   // fetch(pdfUrl)
  //   //   .then(response => response.blob())
  //   //   .then(blob => {
  //   //     const url = window.URL.createObjectURL(blob);
  //   //     const link = document.createElement('a');
  //   //     link.href = url;
  //   //     link.download = 'my-report.pdf';
  //   //     link.click();
  //   //   })
  //   //   .catch(error => console.error(error));
  // };
  const handleDownloadShare = () => {
    if (!shareHolderDetails.share_certificate.url) {
      return; // Handle the case where the URL is not available
    }

    const pdfUrl = shareHolderDetails.share_certificate.url;

    // Option 1: Using `window.open` (more control over download behavior)
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'my-report.pdf';
    link.target = '_blank'; // Open in a new tab/window
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Option 2: Using `fetch` (potential browser compatibility issues)
    // fetch(pdfUrl)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'my-report.pdf';
    //     link.click();
    //   })
    //   .catch(error => console.error(error));
  };

  return (
    <Stack
      spacing={3}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'flex-end', sm: 'center' }}
      sx={{ mb: { xs: 3, md: 5 } }}
    >
      <Stack direction="row" spacing={1} flexGrow={1} sx={{ width: 1 }}>
       


        <PDFDownloadLink
          document={<InvoicePDF invoice={invoice} />}
          fileName={invoice.invoiceNumber}
          style={{
            textDecoration: 'none',
            borderRadius: '30px',
            my: 2,
            backgroundColor: '#145072',
            maxWidth: '200px',
          }}
        >
          {({ loading }) => (
            <Tooltip title="Download">
              <IconButton sx={{ borderRadius: 0, color: '#fff' }}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Iconify icon="eva:cloud-download-fill" />
                )}
                <Typography>Download Invoice</Typography>
              </IconButton>
            </Tooltip>
          )}
        </PDFDownloadLink>
      
        {/* <a href={shareHolderDetails?.share_certificate?.url} download> */}
        {/* <Button
  variant="outlined"
  href="https://cfpub.epa.gov/watertrain/pdf/modules/Birds.pdf"
  download="my-report.pdf"
  sx={{ borderRadius: '30px' }}
>
  <DownloadIcon />
  Download Agreement
</Button> */}
        {/* {invoice?.shareCertificate?.url && (
          // <a
          //   href="invoice.shareCertificate.url"
          //   download="my-report.pdf"
          //   style={{ color: '#145072' }}
          // >
          <Button variant="outlined" onClick={handleDownload} sx={{ borderRadius: '30px' }}>
            <DownloadIcon />
            Download Agreement
          </Button>
          // </a>
        )} */}
        {shareHolderDetails?.share_certificate?.url && (
          // <a
          //   href="invoice.shareCertificate.url"
          //   download="my-report.pdf"
          //   style={{ color: '#145072' }}
          // >
          <Button variant="outlined" onClick={handleDownloadShare} sx={{ borderRadius: '30px' }}>
            <DownloadIcon />
            Download Agreement
          </Button>
          // </a>
        )}
        {/* </a> */}
      </Stack>
    </Stack>
  );
}

InvoiceToolbar.propTypes = {
  invoice: PropTypes.object,
};
