const useAmountToWordsConverter = () => {
  const convertAmountToWords = (amount) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = [
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ];
    const tens = [
      '',
      'Ten',
      'Twenty',
      'Thirty',
      'Forty',
      'Fifty',
      'Sixty',
      'Seventy',
      'Eighty',
      'Ninety',
    ];

    const convertLessThanThousand = (num) => {
      let result = '';
      if (num % 100 < 10) {
        result = units[num % 100];
        num = Math.floor(num / 100);
      } else if (num % 100 < 20) {
        result = teens[num % 10];
        num = Math.floor(num / 100);
      } else {
        result = units[num % 10];
        num = Math.floor(num / 10);
        result = `${tens[num % 10]} ${result}`;
        num = Math.floor(num / 10);
      }
      if (num === 0) return result;
      return `${units[num]} Hundred ${result}`;
    };

    const convert = (num) => {
      if (num === 0) return 'Zero';
      let result = '';
      if (num < 0) {
        result = 'Negative ';
        num = Math.abs(num);
      }

      if (num < 1000) {
        result += convertLessThanThousand(num);
      } else {
        let temp = num;
        result += convertLessThanThousand(Math.floor(temp % 1000));
        temp = Math.floor(temp / 1000);

        if (temp % 1000 !== 0) {
          result = `${convertLessThanThousand(temp % 1000)} Thousand ${result}`;
          temp = Math.floor(temp / 1000);
        }
        if (temp % 1000 !== 0) {
          result = `${convertLessThanThousand(temp % 1000)} Lakh ${result}`;
          temp = Math.floor(temp / 1000);
        }
        if (temp !== 0) {
          result = `${convertLessThanThousand(temp)} Crore ${result}`;
        }
      }
      return result;
    };

    return convert(amount);
  };

  return convertAmountToWords;
};

export default useAmountToWordsConverter;
