import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';

import Main from './main';
import PartnerHeader from './header';

// ----------------------------------------------------------------------

export default function PartnerLayout({ children }) {
  const nav = useBoolean();

  return (
    <>
      <PartnerHeader onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Main sx={{ p: 0, mt: 10 }}>{children}</Main>
      </Box>
    </>
  );
}

PartnerLayout.propTypes = {
  children: PropTypes.node,
};
