import * as Yup from 'yup';
import { useEffect } from 'react';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { resendOtpApi, verifyOtpApi } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { varFade, MotionContainer } from 'src/components/animate';
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function RegisterOtpView() {
  const dispatch = useDispatch();
  const routes = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useBoolean();

  const { loading, verify } = useSelector((state) => ({
    loading: state.auth.loginLoading,
    verify: state.auth.verify,
  }));

  const OtpVerifySchema = Yup.object().shape({
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
    otp: Yup.string()
      .required('OTP required')
      .min(4, 'OTP must be at least 4 characters')
      .max(4, 'OTP must not exceed 4 characters'),
  });

  const defaultValues = {
    phone: verify?.phone || '',
    otp: '',
  };

  const methods = useForm({
    resolver: yupResolver(OtpVerifySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credential = {
      state: {
        ...data,
        userId: verify?.userId,
      },
      routes,
      enqueueSnackbar,
    };
    dispatch(verifyOtpApi(credential));
  });

  useEffect(() => {
    reset({
      phone: verify?.phone,
    });
  }, [reset, verify?.phone]);

  const handleResendOtp = () => {
    if (verify?.phone) {
      const credential = {
        state: {
          phone: verify?.phone,
        },
        enqueueSnackbar,
      };
      dispatch(resendOtpApi(credential));
    } else {
      enqueueSnackbar('Please register agin', { variant: 'error' });
    }
  };

  const renderOtpForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="phone"
        label="Phone number"
        type="number"
        InputLabelProps={{ shrink: true }}
        disabled
      />

      <RHFCode name="otp" length={4} />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
      >
        Verify
      </LoadingButton>

      <Typography
        variant="body2"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {`Don’t have a code? `}
        <Button
          size="small"
          variant="text"
          sx={{
            cursor: 'pointer',
            ml: 1,
          }}
          onClick={confirm.onTrue}
        >
          Resend code
        </Button>
      </Typography>

      <Link
        component={RouterLink}
        href={paths.auth.login_partner}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderOtpHead = (
    <Stack spacing={1} sx={{ mt: 3, mb: 5 }}>
      <Typography variant="h3">Verify Your account!</Typography>

      <Typography variant="body2" sx={{ color: 'green' }}>
        {verify?.message}
      </Typography>
    </Stack>
  );

  return (
    <>
      <Container component={MotionContainer}>
        <m.div variants={varFade().inRight} exit={varFade().outRight}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderOtpHead}
            {renderOtpForm}
          </FormProvider>
        </m.div>
      </Container>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Resend OTP"
        content={
          <>
            Are you sure want to resend OTP to <strong> {verify?.phone} </strong> ?
          </>
        }
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleResendOtp();
              confirm.onFalse();
            }}
          >
            Resend OTP
          </Button>
        }
      />
    </>
  );
}
