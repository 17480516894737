import propType from 'prop-types';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { fDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function PartnerDetailOptional({ watch, activeStep }) {
  const { buyShare } = useSelector((state) => ({
    buyShare: state.share.buyShare,
  }));
  return (
    <>
      {activeStep < 1 && (
        <Stack spacing={1} sx={{ backgroundColor: 'white', mt: 1, maxWidth: '600px' }}>
          <Typography variant="body2">
            Please provide all your personal information, including your address, nominee details,
            account information, and KYC details in this section.
          </Typography>
        </Stack>
      )}

      {activeStep > 1 && (
        <Stack spacing={1} sx={{ backgroundColor: 'white', mt: 1 }}>
          <Typography variant="body2">{buyShare?.name}</Typography>
          <Typography variant="body2">S/o {buyShare?.father_name}</Typography>
          <Typography variant="body2">Dob : {fDate(buyShare?.date_of_birth)} etc..</Typography>
        </Stack>
      )}
    </>
  );
}

PartnerDetailOptional.propTypes = {
  watch: propType.any,
  activeStep: propType.number,
};
