import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { ScrollLink } from 'react-scroll';
import { m, useAnimation } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { Chip, Stack, Button, Divider, IconButton, CardContent } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------
import { fDate } from 'src/utils/format-time';

import { getSettingsData } from 'src/server/api/settings';
import { getOneShareDetails, getTransactionDetailsByShareholder } from 'src/server/api/shares';

import Iconify from 'src/components/iconify';
import { varFade } from 'src/components/animate';

import DownloadInvoice from '../app/partner/stepper/DownloadInvoice';

export default function MySharesList({ shares }) {
  return (
    <>
      <Typography variant="h6" sx={{ my: 3 }}>
        Purchased Shares
      </Typography>

      {shares?.shareholders?.length <= 0 && (
        <>
          <Typography variant="body2">
            You have not made any purchases of shares yet. Please proceed with a new share purchase.
          </Typography>
          <ScrollLink to="apply_share" smooth duration={1000} offset={-50}>
            <Button
              variant="contained"
              sx={{
                borderRadius: 100,
                mb: 2,
                background: '#145072',
                fontWeight: 400,
              }}
              color="info"
              size="large"
            >
              Apply For Shares
            </Button>
          </ScrollLink>
        </>
      )}

      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        sx={{ mb: 5 }}
      >
        {shares?.shareholders?.map((share) => (
          <ShareItem key={share.id} share={share} />
        ))}
      </Box>
    </>
  );
}

MySharesList.propTypes = {
  shares: PropTypes.any,
};

// ----------------------------------------------------------------------

function ShareItem({ share }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useResponsive('down', 'md');

  const {
    name,
    total_amount,
    discount_amount,
    nominee_details,
    purchase_date,
    share_phase,
    share_count,
    is_approved,
  } = share;

  const { buyShare, transaction } = useSelector((state) => ({
    buyShare: state.share.buyShare,
    transaction: state.share.transaction,
  }));

  const isOpen = useBoolean();
  // const isPaymentOpen = useBoolean();
  console.log(buyShare);
  console.log(transaction);
  useEffect(() => {
    const credentials = {
      shareId: share?._id,
      enqueueSnackbar,
    };
    dispatch(getOneShareDetails(credentials));
  }, [dispatch, enqueueSnackbar, share?._id]);

  const getShareDetails = () => {
    isOpen.onToggle();
    const credentials = {
      shareId: share?._id,
      enqueueSnackbar,
    };
    dispatch(getOneShareDetails(credentials));
  };

  const controls = useAnimation();

  useEffect(() => {
    if (isOpen) {
      controls.start({ opacity: 1 });
      window.scrollTo({ top: window.innerHeight / 1.5, behavior: 'smooth' });
    } else {
      controls.start({ opacity: 0 });
    }
  }, [isOpen, controls]);

  const handleClose = async () => {
    isOpen.onToggle();
  };

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsData(credentials));
  }, [dispatch]);

  useEffect(() => {
    if (buyShare?.has_screenshot === true) {
      const credentials1 = {
        shareholderId: share?._id,
        enqueueSnackbar,
      };
      dispatch(getTransactionDetailsByShareholder(credentials1));
    }
  }, [buyShare?.has_screenshot, dispatch, enqueueSnackbar, share?._id]);

  // const onCompletePayment = () => {
  //   isPaymentOpen.onToggle();
  // };

  return (
    <Card
      sx={{
        p: (theme) => theme.spacing(3, 2, 1, 3),
        cursor: 'pointer',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0,
          pb: 2,
        }}
      >
        <Avatar
          alt={name}
          src="/assets/images/about/logo_green.png"
          sx={{ width: 40, height: 40, mr: 2, objectFit: 'contain', borderRadius: 0 }}
        />
        <ListItemText
          primary={`${share_count} Shares`}
          secondary={
            <>
              <Iconify icon="mingcute:location-fill" width={16} sx={{ flexShrink: 0, mr: 0.5 }} />
              share phase {share_phase?.phase}
            </>
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'subtitle2',
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            noWrap: true,
            display: 'flex',
            component: 'span',
            alignItems: 'center',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
        <ListItemText
          primary={`₹ ${total_amount}`}
          secondary={<span style={{ color: 'green' }}>{discount_amount} off</span>}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'subtitle2',
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            noWrap: true,
            display: 'flex',
            component: 'span',
            alignItems: 'center',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      </CardContent>
      <Divider />
      <CardContent sx={{ p: 0, pt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption">Nominee : {nominee_details?.first_name}</Typography>
          <Typography variant="caption">Purchase date : {fDate(purchase_date)}</Typography>
        </Stack>
        <Stack>
          {is_approved ? (
            <Chip label="Approved" size="small" color="info" />
          ) : (
            <Chip label="Pending" size="small" color="warning" />
          )}
        </Stack>
      </CardContent>
      <Stack flexWrap="wrap" sx={{ my: 2 }} spacing={2} direction={mobile ? 'column' : 'row'}>
        {!isOpen.value && (
          <Button
            variant="outlined"
            size="small"
            endIcon={<NavigateNextRoundedIcon />}
            sx={{ color: '#737373', fontWeight: '400' }}
            onClick={getShareDetails}
          >
            View All Details
          </Button>
        )}

        {/* {!transaction?.payment_id && (
          <Button
            variant="contained"
            size="small"
            endIcon={<PaymentIcon />}
            sx={{ fontWeight: '400' }}
            onClick={onCompletePayment}
            color="success"
          >
            Complete Payment
          </Button>
        )} */}
      </Stack>

      {/* {isPaymentOpen.value && <Payment maxWidth="100%" selectData={share} />} */}

      {/* {isOpen.value && ( */}
      <m.div
        initial={{ opacity: 0 }}
        animate={controls}
        transition={{ duration: 0.5 }}
        exit={{ opacity: 0 }}
        className={`zero-padding-container more-details ${isOpen.value && 'active'}`}
      >
        <CardContent sx={{ p: 0, pt: 0 }}>
          <Box>
            {!is_approved && (
              <Typography variant="body2" maxWidth={600} color="green" textAlign="start">
                Your payment is currently under review, and it will be verified and approved by the
                authorized personnel within 48 hours.
              </Typography>
            )}
            {/* {transaction?.status === 'failed' && (
              <Typography variant="body2" maxWidth={600} color="red" textAlign="start">
                Your payment is currently under review and unfortunately, it has not been
                successful. Please take a moment to check the transaction details or consider making
                the payment again.
              </Typography>
            )} */}
            {/* {transaction?.status === 'approved' && ( */}
            {is_approved && (
              <>
                <Typography variant="body2" maxWidth={600} color="green" textAlign="start">
                  Your payment verification has been successfully completed. You can now proceed to
                  download the payment invoice.
                </Typography>
                <DownloadInvoice isMinimal shareCertificate={buyShare?.share_certificate} />
              </>
            )}
          </Box>

          <Box variants={varFade().inRight}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="caption" color={grey[500]}>
                Profile :{' '}
              </Typography>
              <IconButton size="small">
                <ExpandLessRoundedIcon onClick={handleClose} />
              </IconButton>
            </Stack>

            <Typography variant="body2">
              {buyShare?.name} {buyShare?.surname}
            </Typography>
            <Typography variant="body2">S/o {buyShare?.father_name}</Typography>
            <Typography variant="body2">dob : {buyShare?.date_of_birth}</Typography>
            <Typography variant="body2">{buyShare?.phone}</Typography>
            <Typography variant="body2">{buyShare?.email}</Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
          <Typography variant="caption" color={grey[500]}>
            Address :{' '}
          </Typography>
          <Typography variant="body2">{buyShare?.address?.addressLine1}</Typography>
          <Typography variant="body2">{buyShare?.address?.addressLine2}</Typography>
          <Stack direction="row" flexWrap="wrap">
            <Typography variant="body2">{buyShare?.address?.district?.name},&nbsp;</Typography>
            <Typography variant="body2">{buyShare?.address?.state?.name},&nbsp;</Typography>
            <Typography variant="body2">{buyShare?.address?.country?.name}</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />

          <Typography variant="caption" color={grey[500]}>
            Documents :{' '}
          </Typography>
          <Typography variant="body2">
            Aadhaar Number : {buyShare?.id_documents?.aadhaar_number}
          </Typography>
          <Typography variant="body2">Voter Id : {buyShare?.id_documents?.voter_id}</Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="caption" color={grey[500]}>
            Bank Details :{' '}
          </Typography>
          <Typography variant="body2">Bank : {buyShare?.bank_details?.bank_name}</Typography>
          <Typography variant="body2">
            Branch name : {buyShare?.bank_details?.branch_name}
          </Typography>
          <Typography variant="body2">
            Account number : {buyShare?.bank_details?.account_number}
          </Typography>
          <Typography variant="body2">IFSC code : {buyShare?.bank_details?.ifsc_code}</Typography>
          <Divider sx={{ my: 1 }} />

          <Typography variant="caption" color={grey[500]}>
            Nominee :{' '}
          </Typography>
          <Typography variant="body2">
            Aadhaar Number : {buyShare?.nominee_details?.first_name}
            {buyShare?.nominee_details?.last_name}
          </Typography>
          <Typography variant="body2">Relation : {buyShare?.nominee_details?.relation}</Typography>
          <Divider sx={{ my: 1 }} />
          <Link to={paths.dashboard.profileTransaction(share?._id)}>
            <Button
              variant="outlined"
              size="small"
              endIcon={<NavigateNextRoundedIcon />}
              sx={{ color: '#737373', fontWeight: '400' }}
            >
              View Transactions
            </Button>
          </Link>
        </CardContent>
      </m.div>
      {/* )} */}
    </Card>
  );
}

ShareItem.propTypes = {
  share: PropTypes.object,
};
