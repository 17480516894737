import * as Yup from 'yup';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { registerApi } from 'src/server/api/auth';

import { varFade, MotionContainer } from 'src/components/animate';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function RegisterView() {
  const dispatch = useDispatch();
  const mobile = useResponsive('down', 'md');
  const routes = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { loading } = useSelector((state) => ({
    loading: state.auth.loginLoading,
    error: state.auth.error,
  }));

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
    email: Yup.string(),
  });

  const defaultValues = {
    name: '',
    phone: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credential = {
      state: data,
      routes,
      enqueueSnackbar,
    };
    dispatch(registerApi(credential));
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Get started absolutely free</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>

        <Link href={paths.auth.login_partner} component={RouterLink} variant="subtitle2">
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        mt: 2.5,
        textAlign: 'center',
        typography: 'caption',
        color: 'text.secondary',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <RHFTextField name="name" label="Full name" />
      </Stack>

      <RHFTextField name="phone" label="Phone number" type="number" />
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
      >
        Register
      </LoadingButton>
    </Stack>
  );

  return (
    <Container component={MotionContainer} sx={{ mt: mobile ? 10 : '' }}>
      <m.div variants={varFade().inRight} exit={varFade().outRight}>
        {renderHead}
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {renderForm}
        </FormProvider>
        {renderTerms}
      </m.div>
    </Container>
  );
}
