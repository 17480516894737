import { createAsyncThunk } from '@reduxjs/toolkit';

import { useSetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { get, put, post } from '../http';
import { activeSnack } from '../store/common';

export const getAllPurchasedShares = createAsyncThunk(
  'share/buy/all',
  async ({ enqueueSnackbar, page, search, limit, date, user, share_phase }) => {
    try {
      const response = await get(
        `/shareholder/admin/all?page=${page || 1}&limit=${limit || 10}&search=${
          search || ''
        }&share_phase=${share_phase || ''}&user=${user || ''}&dateFrom=${date.from || ''}&dateTo=${
          date.to || ''
        }`
      );
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const buyNewShare = createAsyncThunk(
  'share/buy',
  async ({ state, dispatch, handleNext, enqueueSnackbar }) => {
    try {
      const response = await post(`/shareholder/admin/new`, state);
      if (response?._id) {
        enqueueSnackbar('Successfully Submitted');
        handleNext();
        useSetEncryptLocalData('currentShareholderId', response?._id);
        const credentials = {
          shareId: response?._id,
          enqueueSnackbar,
        };
        dispatch(getOneShareDetails(credentials));
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getOneShareDetails = createAsyncThunk(
  'share/details',
  async ({ shareId, enqueueSnackbar }) => {
    try {
      const response = await get(`/shareholder/admin/${shareId}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updateShareHolderDetails = createAsyncThunk(
  'shareholder/update',
  async ({ state, shareHolderId, dispatch }) => {
    try {
      const URL = `/shareholder/admin/${shareHolderId}`;

      const response = await put(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Share Holder updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllSharePhase = createAsyncThunk(
  'share-phase/list',
  async ({ page, search, limit, dispatch, date }) => { 
    try {
      const response = await get(
        `/share-phase/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit && limit
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllShares = createAsyncThunk(
  'share/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/share/admin/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const uploadPaymentScreenshot = createAsyncThunk(
  'share/screenshot',
  async ({ state, enqueueSnackbar, dispatch }) => {
    try {
      const response = await post(`/transaction/admin/new`, state);
      if (response) {
        enqueueSnackbar('Successfully Submitted');
        const credentials = {
          shareId: response?.shareholder,
          enqueueSnackbar,
        };
        dispatch(getOneShareDetails(credentials));
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getTransactionDetailsByShareholder = createAsyncThunk(
  'share/transaction',
  async ({ enqueueSnackbar, shareholderId }) => {
    try {
      const response = await get(`/transaction/admin/${shareholderId}`);
      if (response) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      // enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const checkShareCountLimit = createAsyncThunk(
  'share/check/count',
  async ({ enqueueSnackbar, sharePhase, state }) => {
    try {
      const response = await post(`/share-phase/partner/check-count/${sharePhase}`, state);
      if (response) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      // enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// transaction
export const getAllTransactions = createAsyncThunk(
  'share/transaction/all',
  async ({ enqueueSnackbar, search, limit, page, sort, date, share_phase, shareholder }) => {
    try {
      const response = await get(
        `/transaction/admin/all?page=${page || 1}&search=${search || ''}&limit=${
          limit || 10
        }&sort=${sort || ''}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&share_phase=${
          share_phase || ''
        }&shareholder=${shareholder || ''}`
      );
      if (response) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updateTransactionDetails = createAsyncThunk(
  'transaction/update',
  async ({ state, transactionId, dispatch }) => {
    try {
      const URL = `/transaction/admin/${transactionId}`;

      const response = await put(URL, state);
      if (response) {
        dispatch(
          getAllTransactions({
            enqueueSnackbar: '',
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_transactions'),
            page: 1,
            sort: '',
            date: '',
            share_phase: '',
            shareholder: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Share Holder updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
