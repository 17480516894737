import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Stack, Divider, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import Payment from 'src/sections/app/partner/stepper/Payment';

// ----------------------------------------------------------------------

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { getSettingsData } from 'src/server/api/settings';
import { getOneShareDetails, getTransactionDetailsByShareholder } from 'src/server/api/shares';

// import Payment from 'src/sections/app/partner/stepper/Payment';

import Label from 'src/components/label';

export default function ProfileTransaction() {
  const route = useRouter();
  const params = useParams();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { transaction, share } = useSelector((state) => ({
    transaction: state.share.transaction,
    share: state.share.buyShare,
  }));

  useEffect(() => {
    const credentials = {
      shareId: params?.id,
      enqueueSnackbar,
    };
    dispatch(getOneShareDetails(credentials));
  }, [dispatch, enqueueSnackbar, params?.id]);

  useEffect(() => {
    const credentials1 = {
      shareholderId: params?.id,
      enqueueSnackbar,
    };
    dispatch(getTransactionDetailsByShareholder(credentials1));
  }, [dispatch, enqueueSnackbar, params?.id]);

  console.log(transaction);

  const isOpen = useBoolean();

  const controls = useAnimation();

  useEffect(() => {
    if (isOpen) {
      controls.start({ opacity: 1 });
      window.scrollTo({ top: window.innerHeight / 1.5, behavior: 'smooth' });
    } else {
      controls.start({ opacity: 0 });
    }
  }, [isOpen, controls]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsData(credentials));
  }, [dispatch]);

  const handleStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ my: 3 }}>
        <IconButton color="success" onClick={() => route.push(paths.dashboard.profile)}>
          <ArrowBackIosIcon sx={{ alignItems: 'center' }} fontSize="small" />
        </IconButton>{' '}
        Transaction History
      </Typography>
      <Stack>
        <Stack>
          <Grid container spacing={3}>
            {transaction?.length === 0 ? (
              <ListItemText
                primary="No transaction found in this purchase"
                secondary="Create new transaction now"
                primaryTypographyProps={{
                  noWrap: true,
                  typography: 'subtitle2',
                  color: 'orange',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  noWrap: true,
                  display: 'flex',
                  component: 'span',
                  alignItems: 'center',
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              />
            ) : (
              <>
                {(transaction || [])?.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      md={3}
                      sx={{
                        p: (theme) => theme.spacing(3, 2, 1, 3),
                        cursor: 'pointer',
                      }}
                    >
                      {/* {transaction === null && (
              <ListItemText
                primary="No transaction found in this purchase"
                secondary="create new transaction now"
                primaryTypographyProps={{
                  noWrap: true,
                  typography: 'subtitle2',
                  color: 'orange',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  noWrap: true,
                  display: 'flex',
                  component: 'span',
                  alignItems: 'center',
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              />
            )} */}

                      <Stack>
                        <Stack>
                          <Stack
                            gap={2}
                            sx={{
                              justifyContent: 'space-between',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <Stack sx={{ mb: 1 }}>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '700',
                                  textDecoration: 'underline',
                                }}
                              >
                                Transaction {index + 1}
                              </Typography>
                            </Stack>
                            <Stack sx={{ width: '50%', mb: 2 }}>
                              <Label color={handleStatusColor(item.status)}>{item.status}</Label>
                            </Stack>
                          </Stack>
                          {item.payment_id && (
                            <Stack sx={{ mb: 2 }}>
                              <Typography
                                sx={{ fontSize: '12px', fontWeight: '400', color: '#637381' }}
                              >
                                Payment Id
                              </Typography>
                              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                {item.payment_id}
                              </Typography>
                            </Stack>
                          )}
                          {item.ref_address && (
                            <Stack sx={{ mb: 2 }}>
                              <Typography
                                sx={{ fontSize: '12px', fontWeight: '400', color: '#637381' }}
                              >
                                Ref Id
                              </Typography>
                              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                {item.ref_address}
                              </Typography>
                            </Stack>
                          )}
                          {item.pay_screenshot && (
                            <Stack sx={{ mb: 2, alignItems: 'center' }}>
                              <img
                                style={{ width: '120px', height: '120px', objectFit: 'contain' }}
                                src={item.pay_screenshot.url}
                                alt="payment screen shoot"
                              />
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Stack>

        <Stack sx={{ mt: 2 }}>
          {' '}
          <Divider sx={{ mb: 3 }} />
          <Payment maxWidth="100%" selectData={share} />
        </Stack>
      </Stack>
      {/* <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        sx={{ mb: 5 }}
      >
        <Card
          sx={{
            p: (theme) => theme.spacing(3, 2, 1, 3),
            cursor: 'pointer',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              // alignItems: 'center',
              p: 0,
              pb: 2,
            }}
          >
            {transaction === null && (
              <ListItemText
                primary="No transaction found in this purchase"
                secondary="create new transaction now"
                primaryTypographyProps={{
                  noWrap: true,
                  typography: 'subtitle2',
                  color: 'orange',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  noWrap: true,
                  display: 'flex',
                  component: 'span',
                  alignItems: 'center',
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              />
            )}

            <Stack>
              {transaction?.map((item, index) => (
                <Stack key={index}>
                  <Stack sx={{ mb: 1 }}>
                    <Typography
                      sx={{ fontSize: '14px', fontWeight: '700', textDecoration: 'underline' }}
                    >
                      Transaction {index + 1}
                    </Typography>
                  </Stack>
                  <Stack sx={{ width: '100%', mb: 2 }}>
                    <Label color={handleStatusColor(item.status)}>{item.status}</Label>
                  </Stack>
                  {item.payment_id && (
                    <Stack sx={{ mb: 2 }}>
                      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#637381' }}>
                        Payment Id
                      </Typography>
                      <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                        {item.payment_id}
                      </Typography>
                    </Stack>
                  )}
                  {item.ref_address && (
                    <Stack sx={{ mb: 2 }}>
                      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#637381' }}>
                        Ref Id
                      </Typography>
                      <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                        {item.ref_address}
                      </Typography>
                    </Stack>
                  )}
                  {item.pay_screenshot && (
                    <Stack sx={{ mb: 2 }}>
                      <img
                        style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                        src={item.pay_screenshot.url}
                        alt="payment screen shoot"
                      />
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          </CardContent>
          <Divider sx={{ mb: 3 }} />

          <Payment maxWidth="100%" selectData={share} />
        </Card>
      </Box> */}
    </>
  );
}

ProfileTransaction.propTypes = {};
