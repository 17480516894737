import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import { alpha } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import { paths } from 'src/routes/routes/paths';

import { useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { getSettingsData } from 'src/server/api/settings';
import {
  buyNewShare,
  getOneShareDetails,
  getTransactionDetailsByShareholder,
} from 'src/server/api/shares';

import FormProvider from 'src/components/hook-form';
import { MotionViewport } from 'src/components/animate';

import Payment from './Payment';
import SelectShare from './SelectShare';
import PaymentOptional from './PaymentOptional';
import PartnerDetailForm from './PartnerDetailForm';
import SelectShareOptional from './SelectShareOptional';
import PartnerDetailOptional from './PartnerDetailOptional';

// ----------------------------------------------------------------------

const maxWidth = 500;

export default function ShareStepper() {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  // form
  const { loading, shareCountLimit, sharePhase, currentShareDetails, transaction } = useSelector(
    (state) => ({
      error: state.auth.error,
      loading: state.share.loading,
      sharePhase: state.share.sharePhase,
      currentShareDetails: state.share.buyShare,
      transaction: state.share.transaction,
      shareCountLimit: state.share.shareCountLimit,
    })
  );
  console.log(currentShareDetails);
  // validation
  const LoginSchema = Yup.object().shape({
    share_phase: Yup.string().required('Share phase is required'),
    share_count: Yup.number().required('Share count is required'),
    name: Yup.string().required('Name is required'),
    surname: Yup.string(),
    date_of_birth: Yup.string().required('Date of birth is required'),
    father_name: Yup.string().required('Name of father is required'),
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
    whatsapp_number: Yup.string()
      .min(10, 'Number must be at least 10 characters')
      .max(10, 'Number must not exceed 10 characters'),
    email: Yup.string().email(),
    address: Yup.object().shape({
      country: Yup.string(),
      addressLine1: Yup.string().required('Address is required'),
      addressLine2: Yup.string(),
      post_office: Yup.string().required('Post office is required'),
      pincode: Yup.number().required('Pincode is required'),
      state: Yup.string(),
      district: Yup.string(),
    }),
    nominee_details: Yup.object().shape({
      first_name: Yup.string(),
      last_name: Yup.string(),
      relation: Yup.string(),
    }),
    bank_details: Yup.object().shape({
      bank_name: Yup.string(),
      branch_name: Yup.string(),
      account_number: Yup.string(),
      ifsc_code: Yup.string(),
    }),
    id_documents: Yup.object().shape({
      aadhaar_number: Yup.number().required('Aadhaar number is required'),
      voter_id: Yup.string(),
    }),
  });

  const currentPhase = sharePhase?.sharePhases?.filter((item) => item?.is_active === true)[0];

  const defaultValues = {
    share_phase: currentPhase?.phase,
    share_count: '',
    name: '',
    surname: '',
    date_of_birth: '',
    father_name: '',
    phone: '',
    whatsapp_number: '',
    email: '',
    address: Yup.object().shape({
      country: '',
      addressLine1: '',
      addressLine2: '',
      pincode: '',
      state: '',
      district: '',
    }),
    nominee_details: Yup.object().shape({
      first_name: '',
      last_name: '',
      relation: '',
    }),
    bank_details: Yup.object().shape({
      bank_name: '',
      branch_name: '',
      account_number: '',
      ifsc_code: '',
    }),
    id_documents: Yup.object().shape({
      aadhaar_number: '',
      voter_id: '',
    }),
  };

  // states
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');

  // transfer data to children
  const lsgData = {
    selectedCountry,
    setSelectedCountry,
    selectedState,
    setSelectedState,
    setSelectedDistrict,
  };

  // handle steps navigation
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // form controllers
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    watch,
  } = methods;

  console.log(errors);

  // const handleShareCountValue = (shareCount) => {
  //   switch (shareCount) {
  //     case 24:
  //       return 25;
  //     case 47:
  //     case 48:
  //     case 49:
  //     case 50:
  //       return 50;
  //     default:
  //       return shareCount;
  //   }
  // };

  const onSubmit = handleSubmit(async (formData) => {
    const credentials = {
      state: {
        ...formData,
        share_phase: currentPhase?._id,
        // share_count: handleShareCountValue(formData?.share_count),
        address: {
          ...formData?.address,
          country: selectedCountry,
          state: selectedState,
          district: selectedDistrict,
        },
      },
      dispatch,
      handleNext,
      enqueueSnackbar,
    };
    console.log(credentials?.state);

    dispatch(buyNewShare(credentials));
  });
  // form end

  const { data: currentShareholderId, isSuccess } = useGetEncryptLocalData('currentShareholderId');

  // get current details
  useEffect(() => {
    const credentials = {
      shareId: currentShareholderId,
      enqueueSnackbar,
    };
    if (currentShareholderId && isSuccess) {
      dispatch(getOneShareDetails(credentials));
    }
  }, [currentShareholderId, dispatch, enqueueSnackbar, isSuccess]);

  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
      shareholderId: currentShareDetails?._id,
    };
    if (currentShareDetails?._id !== undefined) {
      dispatch(getTransactionDetailsByShareholder(credentials));
    }
  }, [currentShareDetails?._id, dispatch, enqueueSnackbar]);

  // update current data to form
  useEffect(() => {
    reset({
      ...currentShareDetails,
      share_phase: currentPhase?.phase,
    });
  }, [currentPhase?.phase, currentShareDetails, currentShareDetails?.share_count, reset]);

  useEffect(() => {
    setSelectedCountry(currentShareDetails?.address?.country?._id);
    setSelectedState(currentShareDetails?.address?.state?._id);
    setSelectedDistrict(currentShareDetails?.address?.district?._id);
  }, [currentShareDetails]);

  // update stepper with current step
  useEffect(() => {
    if (transaction?.payment_id && transaction?.status) {
      setActiveStep(2);
    }
    if (transaction?.payment_id && transaction?.status === 'approved') {
      setActiveStep(3);
    }
  }, [transaction?.payment_id, transaction?.status]);
  console.log(sharePhase);
  // stepper data
  const steps = [
    {
      label: 'Phase and number of share',
      component: <SelectShare watch={watch} maxWidth={maxWidth} currentPhase={currentPhase} />,
      optional: <SelectShareOptional watch={watch} activeStep={activeStep} />,
    },
    {
      label: 'Partner Details',
      component: <PartnerDetailForm maxWidth={maxWidth} lsgData={lsgData} errors={errors} />,
      optional: <PartnerDetailOptional watch={watch} activeStep={activeStep} />,
    },
    {
      label: 'Payment & Upload screenshot',
      component: <Payment maxWidth={650} handleNext={handleNext} />,
      optional: <PaymentOptional activeStep={activeStep} />,
    },
    {
      label: 'Download Invoice',
      component: (
        <>
          {currentShareDetails?.is_approved === true && (
            <Link to={`${paths.dashboard.profile}#profile`} style={{ textDecoration: 'none' }}>
              <Button
                size="medium"
                variant="outlined"
                // onClick={handleProfile}
                sx={{ color: '#145072', pr: 2.5, mr: 2, py: 0.9, borderRadius: '30px' }}
              >
                Go to Profile
              </Button>
            </Link>
          )}
          {!currentShareDetails?.is_approved === true && (
            <Button
              onClick={handleBack}
              size="medium"
              variant="outlined"
              sx={{ color: '#145072', pr: 2.5, mr: 2, py: 0.9, borderRadius: '30px' }}
            >
              <ArrowBackIosNewRoundedIcon fontSize="small" sx={{ height: '12px' }} /> Previous
            </Button>
          )}
        </>
      ),
      // <DownloadInvoice handleBack={handleBack} />,
      optional: (
        <Typography variant="body2" mt={1}>
          {currentShareDetails?.is_approved
            ? 'Go to your profile and download the invoice of your payment.'
            : 'Invoice only will be available after the admin verification. It may take up to 48 hours. After verification, you can download the invoice of your payment through your profile.'}
        </Typography>
      ),
    },
  ];

  // stepper end

  console.log(steps);
  console.log(activeStep);

  // settings
  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsData(credentials));
  }, [dispatch]);

  //
  const shareCount = watch('share_count');

  const handleDisableContinue = () => {
    if (activeStep === 2 && !currentShareDetails?.has_screenshot) {
      return true;
    }
    if (activeStep === 0) {
      if (!shareCountLimit?.is_available) {
        return true;
      }
      if (shareCount <= 0) {
        return true;
      }
    }
    if (
      (activeStep === 2 && transaction?.status === 'pending') ||
      (activeStep === 2 && transaction?.status === 'failed')
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (currentShareholderId) {
      reset({
        share_phase: currentPhase?.phase,
      });
    }
  }, [currentPhase?.phase, currentShareholderId, reset]);

  // const resetForm = useCallback(() => {
  //   reset({
  //     share_phase: currentPhase?.phase,
  //   });
  // }, [currentPhase?.phase, reset]);

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 5, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}
      maxWidth="md"
    >
      <Typography variant="h4" color="#145072" fontWeight={500} sx={{ mb: 3 }}>
        Process Investment
      </Typography>

      <div className="custom-form" id="apply_share">
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps?.map((step, index) => (
              <Step key={step?.label}>
                <StepLabel optional={step?.optional}>{step?.label}</StepLabel>
                <StepContent>
                  <Typography>{step?.component}</Typography>

                  <Typography variant="caption" color="red">
                    {errors?.share_count?.message}
                  </Typography>

                  <Box sx={{ mt: 3, display: 'flex', maxWidth }}>
                    {!currentShareDetails?._id && step?.label === 'Partner Details' ? (
                      <LoadingButton
                        sx={{
                          backgroundColor: '#145072',
                          width: 'fit-content',
                          borderRadius: '30px',
                          minWidth: '100px',
                          color: 'white',
                          mr: 1,
                        }}
                        fullWidth
                        size="medium"
                        color="info"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || loading}
                      >
                        Submit
                      </LoadingButton>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        color="info"
                        sx={{
                          borderRadius: '30px',
                          backgroundColor: '#145072',
                          display: activeStep === 3 ? 'none' : '',
                          mr: 2,
                        }}
                        disabled={handleDisableContinue()}
                      >
                        Continue
                      </Button>
                    )}

                    {step?.label !== 'Download Invoice' && (
                      <Button
                        variant="outlined"
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ color: '#145072', pr: 2.5, borderRadius: '30px' }}
                      >
                        <ArrowBackIosNewRoundedIcon fontSize="small" sx={{ height: '12px' }} />{' '}
                        Previous
                      </Button>
                    )}
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </FormProvider>
      </div>

      {activeStep === steps.length && (
        <Paper
          sx={{
            p: 3,
            mt: 3,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
          }}
        >
          <Typography sx={{ mb: 2 }}>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset}>Reset</Button>
        </Paper>
      )}
    </Container>
  );
}
