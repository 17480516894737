import { m } from 'framer-motion';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link, Stack, Button, Typography } from '@mui/material';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';

import { useResponsive } from 'src/hooks/use-responsive';

import { getSettingsData } from 'src/server/api/settings';

import Image from 'src/components/image';
import { varFade, MotionViewport } from 'src/components/animate';

// ----------------------logo_green.png------------------------------------------------

export default function Support() {
  const mobile = useResponsive('down', 'md');
  const dispatch = useDispatch();

  const { settingsData } = useSelector((state) => ({
    settingsData: state.settings.settingsData,
  }));
  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsData(credentials));
  }, [dispatch]);
  return (
    <Box
      sx={{
        height: { md: 280 },
        py: { xs: mobile ? 5 : 10 },
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#145072',
      }}
    >
      <Container sx={{ height: '100%' }} component={MotionViewport} maxWidth="md">
        <Box
          sx={{
            bottom: { md: 80 },
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: mobile ? 'column' : 'row',
          }}
        >
          <Stack direction="row">
            <m.div variants={varFade().inRight}>
              <Image
                alt="our office 1"
                src="/assets/images/about/logo_green.png"
                sx={{ height: '80px', width: '100px', mr: 3 }}
              />
            </m.div>

            <Stack sx={{ mt: -1 }}>
              <m.div variants={varFade().inRight}>
                <Typography variant="h3" color="white">
                  Need any quick support?
                </Typography>
              </m.div>
              <m.div variants={varFade().inRight}>
                <Typography variant="body2" color="#FFFFFFB2">
                  Contact with our support team
                </Typography>
              </m.div>
            </Stack>
          </Stack>

          <m.div variants={varFade().inRight} style={{ marginTop: mobile ? 50 : 0 }}>
            <Link
              href={`tel:${settingsData?.contact_details?.mobile || ''}`}
              smooth
              duration={1000}
              offset={-50}
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 100,
                  mb: 2,
                  background: '#01B0DC',
                  color: '#fff',
                  fontWeight: 400,
                  border: 'none',
                }}
                color="inherit"
                size="large"
                startIcon={<LocalPhoneRoundedIcon />}
              >
                Contact Us
              </Button>
            </Link>
          </m.div>

          {/* <Stack spacing={2} display="inline-flex" direction="row" sx={{ color: 'common.white' }}>
            <TextAnimate text="we" />
            <TextAnimate text="are?" />
          </Stack> */}
        </Box>
      </Container>
    </Box>
  );
}

// ----------------------------------------------------------------------

function TextAnimate({ text, variants, sx, ...other }) {
  return (
    <Box
      component={m.div}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter, index) => (
        <m.span key={index} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}

TextAnimate.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
  variants: PropTypes.object,
};
