import { createAsyncThunk } from '@reduxjs/toolkit';

import { post } from '../http';

export const uploadFile = createAsyncThunk(
  'file/upload',
  async ({ file, enqueueSnackbar, onClose, folder }) => {
    try {
      const response = await post(`/file/admin/upload/${folder}`, { file: file[0]?.preview });
      if (response) {
        console.log(response);
        onClose();
        enqueueSnackbar('File Uploaded');

        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
