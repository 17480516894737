import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from '../http';
import { activeSnack } from '../store/common';

export const registerPartner = createAsyncThunk(
  'partner/register',
  async ({ state, reset, enqueueSnackbar, openDialog }) => {
    try {
      const URL = `/partner/register`;
      const response = await post(URL, state);
      if (response) {
        enqueueSnackbar('Partner Registration Form Submitted Successfully!');
        reset();
        openDialog();
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const createUser = createAsyncThunk(
  'user/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/user/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Partner created successfully' }));
        dispatch(
          getAllUsers({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllUsers = createAsyncThunk(
  'users/list',
  async ({ page, search, dispatch, limit, date, country }) => {
    try {
      const URL = `/user/admin/all?page=${page && page}&search=${search && search}&limit=${
        limit && limit
      }&country=${country || ''}&dateFrom=${date.from}&dateTo=${date.to}`;

      const URL_DROPDOWN = `/user/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      console.log(response);
      if (response) {
        return {
          // response,
          users: response?.users,
          total: response?.total,
          page: response?.page,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getUserDetails = createAsyncThunk('user/single', async ({ userId, dispatch }) => {
  console.log(userId);
  try {
    const response = await get(`/user/admin/${userId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateUserDetails = createAsyncThunk(
  'user/update',
  async ({ state, userId, dispatch, navigate, tabChange }) => {
    try {
      const URL = `/user/admin/${userId}`;

      const response = await put(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'User updated Successfully' }));
        if (navigate) {
          navigate();
        }
        if (tabChange) {
          tabChange();
        }
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async ({ userId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/user/admin/${userId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Partner  deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// partner profile
export const getPartnerProfile = createAsyncThunk(
  'partner/profile',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get(`/partner`);
      console.log(response);
      if (response) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
