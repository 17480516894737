import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createUser,
  deleteUser,
  getAllUsers,
  getUserDetails,
  registerPartner,
  getPartnerProfile,
  updateUserDetails,
} from '../api/users';

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    error: {},
    // user
    users: {},
    staffDropList: [],
    userDetail: {},
    profile: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getPartnerProfile.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = {};
    },
    [getPartnerProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // register partner details
    [registerPartner.pending]: (state) => {
      state.loading = true;
    },
    [registerPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.partnerDetails = action.payload;
    },
    [registerPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create user details
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partner?.partners;
      const oldPartnersList = jsonState?.partner?.partners;

      const modifiedPartnerList = {
        ...jsonState,
        partners: Array.isArray(oldPartnersList)
          ? [action.payload?.createUserData, ...oldPartnersList]
          : [action.payload?.createUserData],
      };
      state.loading = false;
      state.userDetail = action.payload;
      state.users = modifiedPartnerList;
      state.error = {};
    },
    [createUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all user
    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = {};
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single user details
    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetail = action.payload;
      state.error = {};
    },
    [getUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update partner details
    [updateUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      state.error = {};
    },
    [updateUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteUser.pending]: (state) => {
      state.loading = true;
    },
    // [deleteUser.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.users;
    //   const modifiedPartnerList = {
    //     ...jsonState,
    //     users: jsonState.users?.filter((user) => user._id !== action.payload),
    //   };
    //   state.loading = false;
    //   state.user = modifiedPartnerList;
    //   state.error = {};
    // },
    [deleteUser.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const modifiedCityList = {
        ...jsonState,
        users: jsonState.users?.filter((schoolDivisions) => schoolDivisions._id !== action.payload),
      };

      state.loading = false;
      state.users = modifiedCityList;
      state.error = {};
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = userSlice.actions;

export default userSlice.reducer;
