import { m } from 'framer-motion';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { varFade, MotionViewport } from 'src/components/animate';

import ShareOffer from './share-offer';
import ShareTable from './share-table';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Development', 'Design', 'Marketing'][index],
  value: [20, 40, 60][index],
}));

// ----------------------------------------------------------------------

export const offerBanner = [
  {
    id: 'offerBanner01',
    name: 'offer1',
    coverUrl: '/assets/images/about/share_image.png',
  },
  {
    id: 'offer',
    name: 'offer2',
    coverUrl: '/assets/images/about/share_image1.png',
  },
];

export default function ShareValue() {
  const web = useResponsive('up', 'md');
  const mobile = useResponsive('down', 'md');

  return (
    <div
      style={{
        backgroundColor: '#F6F6F6',
      }}
    >
      <Container
        component={MotionViewport}
        sx={{
          py: { xs: 5, md: 15 },
          mb: 5,
          textAlign: { xs: 'center', md: 'unset' },
          minHeight: '500px',
        }}
      >
        <Grid container columnSpacing={{ md: 3 }} alignItems="flex-start">
          <Grid container xs={12} md={6} lg={4} alignItems="center" sx={{ px: web ? 10 : 1 }}>
            <Grid xs={12} mt={5}>
              <m.div variants={varFade().inUp} className="my_about_image my_share_image">
                <ShareOffer sx={{ height: '300px', width: '320px' }} list={offerBanner} />
              </m.div>
            </Grid>
          </Grid>

          <Grid xs={12} md={6} lg={8} sx={{ px: web ? 10 : 1, mt: mobile && 25 }}>
            <m.div variants={varFade().inRight}>
              <Typography variant="h3" sx={{ mb: 3, color: '#145072', fontWeight: 600 }}>
                Share Details
              </Typography>
            </m.div>
            <m.div variants={varFade().inRight}>
              <ShareTable />
            </m.div>
          </Grid>
        </Grid>
      </Container>
    </div>
    // </Box>
  );
}
