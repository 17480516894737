import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, Autocomplete } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllCountries } from 'src/server/api/country';
import { getAllState, getAllDistrict } from 'src/server/api/address';
import { getSettingsData, updateSettingsData } from 'src/server/api/settings';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// ----------------------------------------------------------------------

export default function UpdateSettingsData({ setCurrentTab }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const mdUp = useResponsive('up', 'md');

  const { loading, countryList, stateList, districtList, settingsData } = useSelector((state) => ({
    loading: state.settings.loading,
    countryList: state.country.country,
    stateList: state.address.state,
    districtList: state.address.district,
    settingsData: state.settings.settingsData,
  }));

  const [logoImagePreview, setLogoImagePreview] = useState([]);
  const [qrCodePreview, setQrCodePreview] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();

  const companyDetails = settingsData?.company;
  const accountData = settingsData?.account_credentials;

  const contactInformations = settingsData?.contact_details;
  const accountInformations = settingsData?.account_credentials;
  const socialInformations = settingsData?.social_links;

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsData(credentials));
  }, [dispatch]);

  const NewJobSchema = Yup.object().shape({
    company: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      description: Yup.string(),
      address: Yup.object().shape({
        addressLine1: Yup.string(),
        addressLine2: Yup.string(),
        pincode: Yup.number().required('Pincode is required'),
        country: Yup.object(),
        state: Yup.object(),
        district: Yup.object(),
      }),
    }),
    contact_details: Yup.object().shape({
      email: Yup.string().email(),
      mobile: Yup.number(),
      website: Yup.string(),
      whatsApp: Yup.string(),
    }),
    account_credentials: Yup.object().shape({
      account_number: Yup.number(),
      bank_name: Yup.string(),
      account_holder_name: Yup.string(),

      branch_name: Yup.string(),
      ifsc_code: Yup.string(),
    }),
    social_links: Yup.object().shape({
      facebook: Yup.string(),
      instagram: Yup.string(),
      whatsApp: Yup.string(),
      x: Yup.string(),
    }),
  });
  const defaultCountry = useMemo(
    () => ({
      label: companyDetails?.address?.country?.name,
      value: companyDetails?.address?.country?._id,
    }),
    [companyDetails?.address?.country]
  );
  const defaultState = useMemo(
    () => ({
      label: companyDetails?.address?.state?.name,
      value: companyDetails?.address?.state?._id,
    }),
    [companyDetails?.address?.state]
  );
  const defaultDistrict = useMemo(
    () => ({
      label: companyDetails?.address?.district?.name,
      value: companyDetails?.address?.district?._id,
    }),
    [companyDetails?.address?.district]
  );

  console.log(companyDetails);

  const defaultValues = useMemo(
    () => ({
      company: {
        name: companyDetails?.name || '',
        description: companyDetails?.description || '',
        address: {
          addressLine1: companyDetails?.address?.addressLine1 || '',
          addressLine2: companyDetails?.address?.addressLine2 || '',
          pincode: companyDetails?.address?.pincode || 0,
          country: defaultCountry || {},
          state: defaultState || {},
          district: defaultDistrict || {},
        },
        company_logo: logoImagePreview || [],
      },
      contact_details: {
        email: contactInformations?.email || '',
        mobile: contactInformations?.mobile || '',
        website: contactInformations?.website || '',
        whatsApp: contactInformations?.whatsApp || '',
      },
      account_credentials: {
        account_number: accountInformations?.account_number || 0,
        bank_name: accountInformations?.bank_name || '',
        account_holder_name: accountInformations?.account_holder_name || '',

        branch_name: accountInformations?.branch_name || '',
        ifsc_code: accountInformations?.ifsc_code || '',
        qr_code: qrCodePreview || [],
      },
      social_links: {
        facebook: socialInformations?.facebook || '',
        instagram: socialInformations?.instagram || '',
        whatsApp: socialInformations?.whatsApp || '',
        x: socialInformations?.x || '',
      },
    }),
    [
      companyDetails,
      defaultDistrict,
      defaultCountry,
      defaultState,
      contactInformations,
      accountInformations,
      socialInformations,
      logoImagePreview,
      qrCodePreview,
    ]
  );
  console.log(accountInformations?.account_holder_name)

  const methods = useForm({
    resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (settingsData) {
      reset(defaultValues);
    }
  }, [settingsData, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    const settingsId = settingsData?._id;
    const modifiedData = {
      ...data,
      company: {
        ...data.company,
        address: {
          ...data.company.address,
          country: selectedCountry,
          state: selectedState,
          district: selectedDistrict,
        },
        company_logo: logoImagePreview,
      },
      account_credentials: {
        ...data.account_credentials,
        qr_code: qrCodePreview,
      },
    };

    const credentials = {
      state: modifiedData,
      settingsId,
      dispatch,
      router,
    };
    dispatch(updateSettingsData(credentials));
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (companyDetails?.company_logo?.length >= 0) {
      setLogoImagePreview(companyDetails?.company_logo);
    }
    if (accountData?.qr_code?.length >= 0) {
      setQrCodePreview(accountData?.qr_code);
    }
  }, [companyDetails, accountData]);

  useEffect(() => {
    setSelectedCountry(companyDetails?.address?.country?._id);
    setSelectedState(companyDetails?.address?.state?._id);
    setSelectedDistrict(companyDetails?.address?.district?._id);
  }, [companyDetails]);

  // country

  const [searchValue, setSearchValue] = useState('');
  const optionCountry = [
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValue,
      dispatch,
      limit: 10,
    };
    // console.log('Credentials:', credentials);
    dispatch(getAllCountries(credentials));
  }, [dispatch, searchValue]);

  const handleAutocompleteChange = (_, newValue) => {
    setSelectedCountry(newValue?.value || '');
  };
  const handleTextFieldChange = (event) => {
    setSearchValue(event.target.value);
  };

  // state

  const [searchValueState, setSearchValueSate] = useState('');

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueState,
      limit: 10,
      dispatch,
      countryId: selectedCountry,
    };
    // console.log('Credentials:', credentials);
    dispatch(getAllState(credentials));
  }, [dispatch, searchValueState, selectedCountry]);

  const handleAutocompleteChangeState = (_, newValue) => {
    setSelectedState(newValue?.value || '');
  };
  const handleTextFieldChangeState = (event) => {
    setSearchValueSate(event.target.value);
  };
  const optionState = [
    ...(stateList?.states || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];
  console.log(logoImagePreview);
  // district

  const [searchValueDistrict, setSearchValueDistrict] = useState('');

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueDistrict,
      limit: 10,
      dispatch,
      stateId: selectedState,
    };
    dispatch(getAllDistrict(credentials));
  }, [dispatch, searchValueDistrict, selectedState]);

  const handleAutocompleteChangeDistrict = (_, newValue) => {
    setSelectedDistrict(newValue?.value || '');
  };
  const handleTextFieldChangeDistrict = (event) => {
    setSearchValueDistrict(event.target.value);
  };

  const optionDistrict = [
    ...(districtList?.districts || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const basicDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Company Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the details of the company in this section
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField name="company.name" required label="Name" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="company.description" required label="Description" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                name="company.address.addressLine1"
                placeholder="Address Line 1"
                type="textarea"
                required
                label="Address Line 1"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                name="company.address.addressLine2"
                type="textarea"
                placeholder="Address Line 2"
                label="Address Line 2"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                required
                name="company.address.pincode"
                label="Pincode"
                type="number"
                placeholder="Pincode"
              />
            </Stack>
            <Stack>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: '100%' }}
                options={optionCountry}
                autoHighlight
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChange}
                defaultValue={
                  defaultCountry?.value !== undefined
                    ? defaultCountry
                    : {
                        label: 'select country...',
                        value: '',
                      }
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    required
                    name="country"
                    size="small"
                    value={selectedCountry}
                    onChange={handleTextFieldChange}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Stack>

            <Stack sx={{ flexDirection: 'row' }} spacing={2}>
              {selectedCountry && (
                <Autocomplete
                  id="state-select-demo"
                  sx={{ width: '50%' }}
                  options={optionState}
                  autoHighlight
                  defaultValue={
                    defaultState?.value !== undefined
                      ? defaultState
                      : {
                          label: 'select state...',
                          value: '',
                        }
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={handleAutocompleteChangeState}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      required
                      size="small"
                      name="address.state"
                      onChange={handleTextFieldChangeState}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              )}
              {selectedState && (
                <Autocomplete
                  id="district-select-demo"
                  sx={{ width: '50%' }}
                  options={optionDistrict}
                  defaultValue={
                    defaultDistrict?.value !== undefined
                      ? defaultState
                      : {
                          label: 'select district...',
                          value: '',
                        }
                  }
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  onChange={handleAutocompleteChangeDistrict}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="District"
                      size="small"
                      name="address.district"
                      onChange={handleTextFieldChangeDistrict}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              )}
            </Stack>
            <Typography variant="body2">Company Logo</Typography>
            <FileUploadCustom
              // uploadUrl="/stemxpert"
              folder="logo"
              setImagesPreview={setLogoImagePreview}
              imagesPreview={logoImagePreview}
              title="Upload Image"
              isMultiple
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'image',
                handleDrop,
              }}
            />
            {/* <Stack spacing={1.5}>
              <RHFTextField name="qualification" label="Qualification" />
            </Stack> */}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const contactDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Contact Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Contact Details.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField name="contact_details.email" label="Email" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="contact_details.mobile" label="Mobile" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="contact_details.website" label="Website" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="contact_details.whatsApp" label="Whatsapp" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const accountDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Account Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Account Details.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField name="account_credentials.account_number" label="Account Number" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="account_credentials.account_holder_name" label="Account Holder Name" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="account_credentials.bank_name" label="Bank Name" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="account_credentials.branch_name" label="Branch Name" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="account_credentials.ifsc_code" label="IFSC" />
            </Stack>
            <Typography variant="body2">QR Code</Typography>
            <FileUploadCustom
              // uploadUrl="/stemxpert"
              folder="logo"
              setImagesPreview={setQrCodePreview}
              imagesPreview={qrCodePreview}
              title="Upload Image"
              isMultiple
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'image',
                handleDrop,
              }}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const socialLinks = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Social Links
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Social Links.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField name="social_links.facebook" label="Facebook" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="social_links.instagram" label="Instagram" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="social_links.whatsApp" label="Whatsapp" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField name="social_links.x" label="Twitter" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting || loading}
          sx={{ ml: 2, width: '250px' }}
          color="info"
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderActions}
        {basicDetails}

        {contactDetails}

        {accountDetails}

        {socialLinks}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}

UpdateSettingsData.propTypes = {
  setCurrentTab: PropTypes.func,
};
