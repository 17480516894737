import propType from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ----------------------------------------------------------------------

export default function SelectShareOptional({ watch, activeStep }) {
  //   const { countryList } = useSelector((state) => ({
  //     countryList: state.country.country,
  //   }));

  const phase = watch('share_phase');
  const count = watch('share_count');

  return (
    <>
      {activeStep !== 0 && (
        <Stack sx={{ backgroundColor: 'white', mt: 1 }}>
          <Typography variant="body2">
            Phase {phase} : {count || 0} Shares
          </Typography>
        </Stack>
      )}
    </>
  );
}

SelectShareOptional.propTypes = {
  watch: propType.any,
  activeStep: propType.number,
};
