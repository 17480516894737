import { useSelector } from 'react-redux';

// @mui

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import DashboardLoading from 'src/components/loading/DashboardLoading';

import PartnerDashboardContent from 'src/sections/app/partner';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardPartnerApp() {
  const { dashLoading } = useSelector((state) => ({
    dashLoading: state.dashboard.loading,
  }));

  return (
    <>
      <MetaHelmet title="Dashboard" />

      {!dashLoading ? <PartnerDashboardContent /> : <DashboardLoading />}
    </>
  );
}
