import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { verifyOtpApi, partnerLoginApi } from 'src/server/api/auth';

import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';
// ----------------------------------------------------------------------

export default function LoginPartnerView({ role }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const routes = useRouter();

  const { error, loading, user } = useSelector((state) => ({
    error: state.auth.error,
    loading: state.auth.loginLoading,
    user: state.auth.user,
  }));

  const [renderOtp, setRenderOtp] = useState();

  const LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
  });

  const defaultValues = {
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  console.log('title =>');
  console.log('data :', user);

  const onSubmit = handleSubmit(async (loginFormData) => {
    console.log(loginFormData);
    const credentials = {
      state: loginFormData,
      enqueueSnackbar,
      toggle: () => setRenderOtp(true),
    };

    const otpCredential = {
      state: {
        ...loginFormData,
        userId: user?.userId,
      },
      routes,
      enqueueSnackbar,
    };

    if (renderOtp && user?.userId) {
      dispatch(verifyOtpApi(otpCredential));
    } else {
      dispatch(partnerLoginApi(credentials));
    }
  });

  const renderHead = (
    <Stack spacing={0.5} sx={{ mb: 2 }}>
      <Typography variant="h4">{role} Login</Typography>
      <Typography sx={{ fontSize: '.7rem', color: '#9EA0A3' }}>
        Please provide your credentials
      </Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="phone" label="Phone number" />

      {renderOtp && user?.userId && (
        <>
          <Typography variant="body2">Enter OTP send to your phone number</Typography>
          <RHFCode name="otp" length={4} />
        </>
      )}

      <LoadingButton
        sx={{ backgroundColor: '#145072', color: 'white' }}
        fullWidth
        color="info"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
      >
        Login
      </LoadingButton>

      <Typography
        sx={{ fontWeight: '600', color: '#767676', fontSize: '14px', textAlign: 'center' }}
      >
        Don’t have an account? &nbsp;
        <Link
          href={`${paths.auth.register}`}
          variant="body2"
          underline="always"
          sx={{
            alignSelf: 'flex-end',
            color: '#00A76F',
            fontSize: '14px',
            cursor: 'pointer',
            fontWeight: '700',
            textDecorationColor: '#00A76F',
          }}
        >
          Register
        </Link>
      </Typography>
    </Stack>
  );

  return (
    <Stack sx={{ backgroundColor: 'white', padding: '3rem 2rem', borderRadius: '10px ' }}>
      {renderHead}

      {error?.message && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error?.message}
        </Alert>
      )}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
    </Stack>
  );
}
LoginPartnerView.propTypes = {
  role: PropTypes.string,
};
