import propType from 'prop-types';
import { useEffect } from 'react';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllSharePhase, checkShareCountLimit } from 'src/server/api/shares';

import { RHFTextField } from 'src/components/hook-form';
import { varFade, varBounce, MotionContainer } from 'src/components/animate';
// ----------------------------------------------------------------------

export default function SelectShare({ maxWidth, watch, currentPhase }) {
  const dispatch = useDispatch();
  const mobile = useResponsive('down', 'md');

  const { enqueueSnackbar } = useSnackbar();
  const shareCount = watch('share_count');
  const sharePhase = watch('share_phase');
  const { shareCountLimit } = useSelector((state) => ({
    shareCountLimit: state.share.shareCountLimit,
  }));

  const renderHead = (
    <Stack spacing={0.5} sx={{ mb: 2 }}>
      <Typography variant="body2" sx={{ color: '#9EA0A3' }}>
        Select the phase and number of shares that you want to be partnered with the company
      </Typography>
    </Stack>
  );

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      limit: '',
      dispatch,
      date: '',
    };
    dispatch(getAllSharePhase(credentials));
  }, [dispatch]);

  useEffect(() => {
    if (shareCount && currentPhase?._id) {
      const credentials = {
        sharePhase: currentPhase?._id,
        enqueueSnackbar,
        state: {
          share_count: shareCount,
        },
      };
      dispatch(checkShareCountLimit(credentials));
    }
  }, [currentPhase?._id, dispatch, enqueueSnackbar, shareCount, sharePhase]);

  const limitText = () => {
    let message = '';
    if (shareCount >= 1) {
      if (shareCountLimit?.is_available) {
        message = `shares available`;
      } else {
        message = ` only ${shareCountLimit?.balance_share} shares available`;
      }
      // ${shareCount} shares are not available in phase ${sharePhase},
    }
    return message;
  };

  const renderForm = (
    <Stack spacing={2} direction={mobile ? 'column' : 'row'} maxWidth={maxWidth}>
      <RHFTextField
        size="small"
        sx={{ color: '#145072' }}
        name="share_phase"
        label="Share Phase"
        type="number"
        InputLabelProps={{ shrink: true }}
        required
        disabled
      />

      {/* <Autocomplete
        id="country-select-demo"
        sx={{ width: '100%', borderRadius: '50px' }}
        options={optionPhase}
        autoHighlight
        // disabled
        size="small"
        getOptionLabel={(option) => option.label}
        onChange={handleAutocompleteChange}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Phase"
            required
            name="share_phase"
            value={selectedPhase}
            onChange={handleTextFieldChange}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      /> */}
      <RHFTextField
        size="small"
        sx={{ color: '#145072' }}
        name="share_count"
        label="No.of shares"
        type="number"
        required
        helperText={limitText()}
        InputProps={{ style: { color: shareCountLimit?.is_available ? 'green' : 'red' } }}
        inputProps={{ min: 1 }}
        FormHelperTextProps={{ style: { color: shareCountLimit?.is_available ? 'green' : 'red' } }}
      />
    </Stack>
  );

  const handleShareCount = () => {
    switch (shareCount) {
      case 24:
      case 47:
        // case 48:
        // case 49:
        return true;
      default:
        return false;
    }
  };
  const handleShareCountValue = () => {
    switch (shareCount) {
      case 24:
        return 'one';
      case 47:
        return 'three';

      // case 48:
      //   return 'two';
      // case 49:
      //   return 'one';
      default:
        return '';
    }
  };

  return (
    <Stack sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
      {renderHead}
      {renderForm}
      {handleShareCount() && (
        <Container component={MotionContainer}>
          <m.div variants={{ ...varFade().inRight, ...varBounce().inRight }}>
            <Typography
              variant="caption"
              mt={0.6}
              color="green"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <DoneAllRoundedIcon fontSize="small" sx={{ mr: 0.7, height: '15px' }} />
              You will receive an additional {handleShareCountValue()} shares as part of our promo
              offer.
            </Typography>
          </m.div>
        </Container>
      )}
    </Stack>
  );
}

SelectShare.propTypes = {
  maxWidth: propType.number,
  watch: propType.any,
  currentPhase: propType.any,
};
