import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text, Font, Image, Document, StyleSheet } from '@react-pdf/renderer';

import useAmountToWordsConverter from 'src/hooks/use-amount-to-words-converter';

import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';

import sign from 'src/assets/other/receipt.png';
import sign1 from 'src/assets/other/receipt1.png';
// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        mt40: { marginTop: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Roboto',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 120px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          padding: '8px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '5%',
        },
        tableCell_2: {
          width: '50%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '15%',
        },
        tableCell_full: {
          width: '70%',
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function InvoicePDF({ invoice }) {
  const transaction = invoice?.transaction?.[0];

  const shareDetails = invoice?.shareDetails;
  console.log(shareDetails);

  const styles = useStyles();
  const convertAmountToWords = useAmountToWordsConverter();
  console.log(transaction);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image
            source="/logo/logo_dark.png"
            style={{ width: 120, height: 40, objectFit: 'contain' }}
          />
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>CASH RECEIPT TO</Text>
            <View style={{ marginLeft: 5 }}>
              <Text style={styles.body2}>Mr/Mrs. {transaction?.shareholder?.name}</Text>
              <Text style={styles.body2}>{transaction?.shareholder?.addressLine1}</Text>
              <Text style={styles.body2}>{transaction?.shareholder?.addressLine2}</Text>
              <Text style={styles.body2}>{transaction?.shareholder?.phone}</Text>
            </View>
          </View>

          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text>Ref.No {transaction?.ref_address} </Text>
            <Text>Date {fDate(transaction?.createdAt, 'dd-MM-yyyy')} </Text>
          </View>
        </View>

        {/* <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>Date create</Text>
            <Text style={styles.body2}>{fDate(createDate)}</Text>
          </View>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>Due date</Text>
            <Text style={styles.body2}>{fDate(dueDate)}</Text>
          </View>
        </View> */}

        <Text style={[styles.subtitle1, styles.mb8]}>Invoice Details</Text>

        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>SL</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>No. Shares</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}> S. Value</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text>1</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>
                  Finv Electro ventures LLP {transaction?.shareholder?.share_count} Nos Shares{' '}
                </Text>
                <Text> Online Transfer, Tran ID : {transaction?.payment_id}</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text>{transaction?.shareholder?.share_count}</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text>{shareDetails?.share_phase?.single_share_amount}</Text>
              </View>
              {/* {shareDetails?.share_phase?.single_share_amount && (
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>
                    {fCurrency(
                      shareDetails?.share_phase?.single_share_amount *
                        transaction?.shareholder?.share_count
                    )}
                  </Text>
                </View>
              )} */}
              {shareDetails?.share_phase?.single_share_amount &&
                transaction?.shareholder?.share_count && (
                  <View style={[styles.tableCell_3, styles.alignRight]}>
                    <Text>
                      {fCurrency(
                        (shareDetails?.share_phase?.single_share_amount ?? 0) *
                          (transaction?.shareholder?.share_count ?? 0)
                      )}
                    </Text>
                  </View>
                )}
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(shareDetails?.share_amount)}</Text>
              </View>
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Discount</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>
                  {shareDetails.discount_amount ? fCurrency(-shareDetails.discount_amount) : '0'}
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_full}>
                <Text style={{ fontWeight: 500, textAlign: 'center', marginTop: '3px' }}>
                  Rupees {convertAmountToWords(shareDetails?.total_amount || 0)} only
                </Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}> {fCurrency(shareDetails?.total_amount)}</Text>
                <br />
              </View>
            </View>

            <View style={[styles.gridContainer, styles.mb40, styles.mt40]}>
              <View style={styles.col6}>
                <Image source={sign1} style={{ width: 120, height: 40, objectFit: 'contain' }} />
              </View>

              <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
                <Image source={sign} style={{ width: 120, height: 40, objectFit: 'contain' }} />
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]} fixed>
          <View style={styles.col8}>
            <Text>
              Here we confirm that we have received the above mentioned amount with mentioned
              references. Beneficiary account details: Finv Electroventures LLP. Kerala Gramin Bank,
              Malappuram Branch, A/C No; 40112111000654 IFSC Code: KLGB0040112
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

InvoicePDF.propTypes = {
  invoice: PropTypes.object,
};
