import { useLocation } from 'react-router';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import AccountGeneral from './account-general';
import AccountOverView from './account-overview';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountView() {
  const location = useLocation();
  const settings = useSettingsContext();
  const { role } = useGetRoles();

  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    if (location?.hash) {
      const hash = location?.hash?.split('#')[1];
      setCurrentTab(hash);
    }
  }, [location?.hash]);

  console.log(currentTab);

  const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="ic:round-vpn-key" width={24} />,
    },
  ];

  if (role !== 'admin') {
    TABS.splice(0, 0, {
      value: 'general',
      label: 'Overview',
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    });
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Account"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'User', href: paths.dashboard.root },
          { name: 'Account' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS?.map((tab) => (
          <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      {currentTab === 'general' && <AccountOverView type="shares" />}
      {currentTab === 'profile' && <AccountGeneral />}
    </Container>
  );
}
