import { createSlice } from '@reduxjs/toolkit';

import { getSettingsData, updateSettingsData } from '../api/settings';

export const ShareSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: false,
    error: {},
    settingsData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all settingsData
    [getSettingsData.pending]: (state) => {
      state.loading = true;
    },
    [getSettingsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.settingsData = action.payload;
      state.error = {};
    },
    [getSettingsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // updatel settingsData
    [updateSettingsData.pending]: (state) => {
      state.loading = true;
    },
    [updateSettingsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.settingsData = action.payload;
      state.error = {};
    },
    [updateSettingsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = ShareSlice.actions;

export default ShareSlice.reducer;
