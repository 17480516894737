import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import usersReducer from './users';
import ShareReducer from './shares';
import CommonReducer from './common';
import addressReducer from './address';
import countryReducer from './country';
import SettingsReducer from './settings';
import dashboardReducer from './dashboard';

export default configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    address: addressReducer,
    country: countryReducer,
    share: ShareReducer,
    settings: SettingsReducer,
    common: CommonReducer,
  },
});
