// @mui
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getDashboardData } from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import DashboardContent from 'src/sections/app/admin';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  // const { dashLoading } = useSelector((state) => ({
  //   loading: state.common.loading,
  //   dashLoading: state.dashboard.loading,
  // }));
  const dispatch = useDispatch();
  const dashLoading = false;

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getDashboardData(credentials));
    // dispatch(getAuthenticatedUserApi(credentials));
  }, [dispatch]);

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? <DashboardContent /> : <DashboardLoading />}
      </Container>
    </>
  );
}
