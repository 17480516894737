import Footer from 'src/layouts/main/Footer';

import Banner from './banner';
import Support from './support';
import ShareStepper from './stepper';
import AboutCompany from './about-company';
import ShareValue from './share/share-value';
import PurchasedShares from './myShare/myShares';

// ----------------------------------------------------------------------

export default function OverviewBookingView() {
  return (
    <>
      <AboutCompany />
      <ShareValue />
      <Banner />
      <PurchasedShares />
      <ShareStepper />
      <Support />
      <Footer />
    </>
  );
}
