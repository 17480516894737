import { m } from 'framer-motion';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DiscountIcon from '@mui/icons-material/Discount';
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import {
  Card,
  Link,
  Chip,
  Stack,
  Button,
  Avatar,
  Divider,
  Typography,
  ListItemText,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { fDate } from 'src/utils/format-time';

import { getAllPurchasedShares } from 'src/server/api/shares';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
import { varFade, MotionViewport } from 'src/components/animate';
import Carousel, { useCarousel, CarouselArrows } from 'src/components/carousel';
// ----------------------logo_green.png------------------------------------------------

export default function PurchasedShares() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const { myShares } = useSelector((state) => ({
    myShares: state.share.myShares,
  }));

  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
      page: '',
      search: '',
      limit: '',
      date:'',
      user:'',
      share_phase:'',

    };
    dispatch(getAllPurchasedShares(credentials));
  }, [dispatch, enqueueSnackbar]);

  const carousel = useCarousel({
    infinite: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  });

  const carouselsItems = myShares?.shareholders;

  const popover = usePopover();

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  const handleClearData = () => {
    sessionStorage.removeItem('currentShareholderId');
  };

  return (
    <>
      {carouselsItems?.length >= 1 && (
        <Container component={MotionViewport} sx={{ textAlign: 'center', pt: 10 }}>
          <m.div variants={varFade().inDown}>
            <Typography variant="h5" sx={{ color: '#145072' }}>
              Purchased Shares
            </Typography>
          </m.div>

          <Box sx={{ position: 'relative' }}>
            <CarouselArrows
              filled
              shape="rounded"
              onNext={carousel.onNext}
              onPrev={carousel.onPrev}
              leftButtonProps={{
                sx: {
                  left: 24,
                  ...(carouselsItems?.length < 5 && { display: 'none' }),
                },
              }}
              rightButtonProps={{
                sx: {
                  right: 24,
                  ...(carouselsItems?.length < 5 && { display: 'none' }),
                },
              }}
            >
              <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
                {carouselsItems?.map((member) => (
                  <Box
                    key={member?._id}
                    component={m.div}
                    variants={varFade().in}
                    sx={{
                      px: 1.5,
                      py: { xs: 4, md: 6 },
                      cursor: 'pointer',
                    }}
                    onClick={() => handleClickItem(`${paths.dashboard.profile}#general`)}
                  >
                    <MemberCard member={member} />
                  </Box>
                ))}
              </Carousel>
            </CarouselArrows>
          </Box>

          <Box>
            <Link href="#apply_share" smooth duration={1000} offset={-50}>
              <Button
                variant="contained"
                color="inherit"
                size="large"
                sx={{
                  borderRadius: 100,
                  mb: 2,
                  color: '#fffff',
                  background: '#145072',
                  mr: 2,
                  fontWeight: 400,
                }}
                startIcon={
                  <Avatar
                    alt="shares"
                    src="/assets/images/about/logo_green.png"
                    variant="rounded"
                    sx={{ width: 20, height: 15, objectFit: 'contain' }}
                  />
                }
                onClick={handleClearData}
              >
                Buy more shares
              </Button>
            </Link>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              sx={{ borderRadius: 100, mb: 2, color: '#145072', fontWeight: 400 }}
              endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={24} />}
              onClick={() => handleClickItem(`${paths.dashboard.profile}#general`)}
            >
              See more details in your profile
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
}

function MemberCard({ member }) {
  const { share_phase, discount_amount, total_amount, purchase_date, share_count, is_approved } =
    member;
    console.log(is_approved)
  return (
    <Card>
      <Stack sx={{ p: 3, pb: 2 }}>
        <m.div variants={varFade().inRight}>
          <Avatar
            alt="company.name"
            src="/assets/images/about/logo_green.png"
            variant="rounded"
            sx={{ width: 50, height: 50, mb: 2 }}
          />
        </m.div>
        <m.div variants={varFade().inRight}>
          <ListItemText
            sx={{ mb: 1, textAlign: 'start' }}
            primary={<span style={{ color: 'green' }}>₹ {total_amount}</span>}
            secondary={`Purchased date: ${fDate(purchase_date)}`}
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
            secondaryTypographyProps={{
              mt: 1,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </m.div>
        <Stack
          spacing={0.5}
          direction="row"
          alignItems="center"
          sx={{ color: '#145072', typography: 'caption' }}
        >
          <Typography variant="overline">
            <DiscountIcon
              fontSize="small"
              sx={{ height: '13px', width: '13px', mr: 0.3, mb: -0.3 }}
            />
            {share_count} Shares
          </Typography>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box rowGap={1.5} display="grid" gridTemplateColumns="repeat(2, 1fr)" sx={{ p: 3 }}>
        {[
          {
            isActive: true,
            label: <>Phase {share_phase?.phase}</>,
            icon: <Iconify width={16} icon="solar:wad-of-money-bold" sx={{ flexShrink: 0 }} />,
          },

          {
            isActive: !!discount_amount,
            label: <>{discount_amount} off (promo offer)</>,
            icon: <DiscountRoundedIcon fontSize="small" sx={{ height: 14, width: 14 }} />,
          },
          {
            isActive: true,
            label: <>₹ {share_phase?.single_share_amount} / share </>,
            icon: <LooksOneRoundedIcon fontSize="small" sx={{ height: 14, width: 14 }} />,
          },
          {
            isActive: true,
            label: (
              <Stack sx={{ mx: 2 }}>
                {is_approved ? (
                  <Chip label="Approved" size="small" color="info" />
                ) : (
                  <Chip label="Pending" size="small" color="warning" />
                )}{' '}
              </Stack>
            ),
            icon: ' ',
          },
        ].map((item) => (
          <Stack
            spacing={0.5}
            flexShrink={0}
            direction="row"
            alignItems="center"
            sx={{
              color: 'text.disabled',
              //  display: !item?.isActive && 'none',
              minWidth: 0,
            }}
          >
            {item?.isActive ? (
              <>
                {item?.icon}
                <Typography variant="caption" noWrap>
                  {item?.label}
                </Typography>
              </>
            ) : (
              ''
            )}
          </Stack>
        ))}
      </Box>
    </Card>
  );
}

MemberCard.propTypes = {
  member: PropTypes.object,
};
