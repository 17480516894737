import propTypes from 'prop-types';
import { useSelector } from 'react-redux';

import InvoiceToolbar from './invoice-toolbar';

// ----------------------------------------------------------------------

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '& td': {
//     textAlign: 'right',
//     borderBottom: 'none',
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(1),
//   },
// }));
InvoiceDetails.propTypes = {
  shareCertificate: propTypes.any,
};
// ----------------------------------------------------------------------

export default function InvoiceDetails({ shareCertificate }) {
  const { transaction, shareDetails } = useSelector((state) => ({
    transaction: state.share.transaction,
    shareDetails: state.share.buyShare,
  }));

  console.log(transaction);
  console.log(shareCertificate);

  // const convertAmountToWords = useAmountToWordsConverter();

  // const renderTotal = (
  //   <>
  //     <StyledTableRow>
  //       <TableCell colSpan={3} />
  //       <TableCell sx={{ color: 'text.secondary' }}>
  //         <Box sx={{ mt: 2 }} />
  //         Subtotal
  //       </TableCell>
  //       <TableCell width={120} sx={{ typography: 'subtitle2' }}>
  //         <Box sx={{ mt: 2 }} />
  //         {fCurrency(shareDetails?.share_amount)}
  //       </TableCell>
  //     </StyledTableRow>

  //     <StyledTableRow>
  //       <TableCell colSpan={3} />
  //       <TableCell sx={{ color: 'text.secondary' }}>Discount</TableCell>
  //       <TableCell width={120} sx={{ color: 'success.main', typography: 'body2' }}>
  //         {shareDetails.discount_amount ? fCurrency(-shareDetails.discount_amount) : '0'}
  //       </TableCell>
  //     </StyledTableRow>

  //     <StyledTableRow>
  //       <TableCell colSpan={1} />
  //       <TableCell sx={{ typography: 'subtitle1', fontSize: '14px' }} colSpan={3}>
  //         {/* Rupees Twenty five Thousand only */}
  //         Rupees {convertAmountToWords(shareDetails?.total_amount)} only
  //       </TableCell>
  //       <TableCell width={140} sx={{ typography: 'subtitle1' }}>
  //         {fCurrency(shareDetails?.total_amount)}
  //         <br />
  //       </TableCell>
  //     </StyledTableRow>
  //   </>
  // );

  // const renderFooter = (
  //   <Grid container>
  //     <Grid xs={12} md={12} sx={{ py: 3 }}>
  //       <Typography variant="body2">
  //         Here we confirm that we have received the above mentioned amount with mentioned
  //         references. Beneficiary account details: Finv Electroventures LLP. Kerala Gramin Bank,
  //         Malappuram Branch, A/C No; 40112111000654 IFSC Code: KLGB0040112
  //       </Typography>
  //     </Grid>
  //   </Grid>
  // );

  // const renderList = (
  //   <TableContainer sx={{ overflow: 'unset', mt: 5 }}>
  //     <Scrollbar>
  //       <Table sx={{ minWidth: 960 }}>
  //         <TableHead>
  //           <TableRow>
  //             <TableCell width={40}>SL</TableCell>

  //             <TableCell width={100} sx={{ typography: 'subtitle2' }}>
  //               Description
  //             </TableCell>

  //             <TableCell width={110}>No. Shares</TableCell>

  //             <TableCell width={50} align="right">
  //               S. Value
  //             </TableCell>

  //             <TableCell width={50} align="right">
  //               Total
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>

  //         <TableBody>
  //           <TableRow>
  //             <TableCell>1</TableCell>

  //             <TableCell>
  //               <Box sx={{ maxWidth: 560 }}>
  //                 <Typography variant="subtitle2">
  //                   Finv Electro ventures LLP {transaction?.shareholder?.share_count} Nos Shares{' '}
  //                 </Typography>

  //                 <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
  //                   Online Transfer, Tran ID : {transaction?.payment_id}
  //                 </Typography>
  //               </Box>
  //             </TableCell>

  //             <TableCell>{transaction?.shareholder?.share_count}</TableCell>

  //             <TableCell align="right">
  //               {fCurrency(shareDetails.share_phase?.single_share_amount)}
  //             </TableCell>

  //             <TableCell align="right">
  //               {fCurrency(
  //                 shareDetails.share_phase.single_share_amount * transaction.shareholder.share_count
  //               )}
  //             </TableCell>
  //           </TableRow>

  //           {renderTotal}
  //         </TableBody>
  //       </Table>
  //     </Scrollbar>
  //   </TableContainer>
  // );

  const invoice = {
    transaction,
    shareDetails,
    shareCertificate,
  };

  return (
    <>
      <InvoiceToolbar invoice={invoice} />
      {/* <Card sx={{ pt: 5, px: 5 }}>
        <Box
          rowGap={5}
          display="grid"
          alignItems="center"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <Box
            component="img"
            alt="logo"
            src={receipt}
            sx={{ width: 120, height: 40, objectFit: 'contain' }}
          />

          <Stack spacing={0} alignItems={{ xs: 'flex-start', md: 'flex-end' }}>
            <Typography variant="h5">CASH RECEIPT</Typography>
          </Stack>

          <Stack sx={{ typography: 'body2' }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              CASH RECEIPT TO
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 5 }}>
              Mr. {transaction?.shareholder?.name}
              <br />
              {transaction?.shareholder.address?.addressLine1}
              {transaction?.shareholder.address?.addressLine2}
              <br />
              Ph: {transaction?.shareholder.phone}
              <br />
            </Typography>
          </Stack>

          <Stack sx={{ typography: 'body2' }}>
            <Stack spacing={0} alignItems={{ xs: 'flex-start', md: 'flex-end' }}>
              <Label
                variant="soft"
                color={
                  (transaction?.status === 'approved' && 'success') ||
                  (transaction?.status === 'pending' && 'warning') ||
                  (transaction?.status === 'failed' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'capitalize', marginBottom: '10px' }}
              >
                {transaction?.status}
              </Label>

              <Typography variant="subtitle2">
                Ref.No&nbsp;&nbsp;&nbsp;&nbsp; {transaction.ref_address}
              </Typography>
              <Typography variant="subtitle2">
                Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {fDate(transaction?.createdAt, 'dd-MM-yyyy')}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        {renderList}

        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: 8,
          }}
        >
          <Box
            component="img"
            alt="logo"
            src={receipt1}
            sx={{ width: 150, height: 150, objectFit: 'contain' }}
          />
          <Box
            component="img"
            alt="logo"
            src={receipt}
            sx={{ width: 150, height: 150, objectFit: 'contain' }}
          />
        </Stack>

        <Divider sx={{ mt: 5, borderStyle: 'dashed' }} />

        {renderFooter}
      </Card> */}
    </>
  );
}

InvoiceDetails.propTypes = {};
