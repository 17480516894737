import { useMemo } from 'react';

import FlagIcon from '@mui/icons-material/Flag';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

const adminSideBarData = [
  {
    subheader: 'Dashboard',
    items: [{ title: 'Overview', path: paths.dashboard.root, icon: icon('ic_dashboard') }],
  },
  {
    items: [
      { title: 'Shareholders', path: paths.dashboard.share_holders, icon: icon('ic_invoice') },
      { title: 'Transactions', path: paths.dashboard.transaction, icon: icon('ic_banking') },

      { title: 'Share Phase', path: paths.dashboard.share_phases, icon: icon('ic_analytics') },
      { title: 'Users', path: paths.dashboard.users, icon: icon('ic_user') },

      {
        title: 'Settings',
        path: paths.dashboard.settings,
        icon: <Iconify icon="solar:settings-bold-duotone" width={24} />,
      },
    ],
  },
];

const partnerSideBarData = [
  {
    subheader: 'Overview',
    items: [
      { title: 'Dashboard', path: paths.dashboard.root, icon: <DashboardIcon /> },
      { title: 'Earnings', path: paths.dashboard.earnings, icon: <AccountBalanceWalletIcon /> },
      {
        title: 'Partnered Schools',
        path: paths.dashboard.partnership_schools,
        icon: <ApartmentOutlinedIcon />,
      },
    ],
  },

  {
    subheader: 'Sale',
    items: [
      { title: 'Sales', path: paths.dashboard.sale, icon: <FlagIcon /> },
      { title: 'Sales Items', path: paths.dashboard.sale_items, icon: <InventoryIcon /> },
    ],
  },
];

// const schoolSideBarData = [
//   {
//     subheader: 'Dashboard',
//     items: [
//       { title: 'Over View', path: paths.dashboard.root, icon: <DashboardIcon /> },
//       { title: 'School Details', path: paths.dashboard.root, icon: <DashboardIcon /> },
//       { title: 'Classes ', path: paths.dashboard.class, icon: <FlightClassTwoToneIcon /> },
//     ],
//   },

//   {
//     subheader: 'LMS',
//     items: [{ title: 'Courses', path: paths.dashboard.course, icon: <FlightClassTwoToneIcon /> }],
//   },
// ];

export function useNavData() {
  const { role } = useGetRoles();

  console.log(role);

  const data = useMemo(() => {
    const handleSideBarDataWithRole = () => {
      switch (role) {
        case 'admin':
          return adminSideBarData;
        case 'partner':
          return partnerSideBarData;

        default:
          return [];
      }
    };

    return handleSideBarDataWithRole();
  }, [role]);

  return data;
}

// export function useNavData() {
//   const data = useMemo(() => adminSideBarData, []);

//   return data;
// }

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
