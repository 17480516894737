import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetToken } from 'src/hooks/useHandleSessions';

import { get, put, post } from '../http';
import { activeSnack } from '../store/common';

export const loginApi = createAsyncThunk('auth/login', async ({ state, dispatch, navigate }) => {
  try {
    const response = await post('admin/login', state);
    dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
    if (response) {
      useSetToken(response?.token);
      useSetRole(response?.role);
      if (response?.token) {
        navigate(paths.dashboard.root, { replace: true });
      }
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate('/', { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate('/', { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// the following api call are not used
export const partnerLoginApi = createAsyncThunk(
  'auth/partner/login',
  async ({ state, enqueueSnackbar, toggle }) => {
    try {
      const response = await post('/login/mobile', state);
      enqueueSnackbar(response.message || 'success', { variant: 'success' });

      if (response) {
        console.log(response);
        if (response?.otp) {
          enqueueSnackbar(`your otp is ${response?.otp}`, { variant: 'success' });
        }

        if (response?.userId) {
          toggle();
        }

        return response;
      }

      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });

      throw error?.response?.data?.message;
    }
  }
);

export const registerApi = createAsyncThunk(
  'auth/register',
  async ({ state, enqueueSnackbar, routes }) => {
    try {
      const response = await post('/register/mobile', state);
      if (response) {
        enqueueSnackbar(response.message || 'success', { variant: 'success' });
        routes.push(paths.auth.verify);
        return {
          ...state,
          ...response,
        };
      }

      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const verifyOtpApi = createAsyncThunk(
  'auth/verify',
  async ({ state, enqueueSnackbar, routes }) => {
    try {
      const response = await post('/verify-otp', state);
      console.log(response);
      if (response?.token) {
        enqueueSnackbar(response?.message || 'success', { variant: 'success' });
        useSetToken(response?.token);
        useSetRole(response?.role);
        routes.push(paths.dashboard.root, { replace: true });
        return {
          ...state,
          ...response,
        };
      }

      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const resendOtpApi = createAsyncThunk(
  'auth/resend/verify',
  async ({ state, enqueueSnackbar }) => {
    try {
      const response = await post('/resend-otp', state);
      if (response) {
        enqueueSnackbar(response.message || 'success', { variant: 'success' });
        return {
          ...state,
          ...response,
        };
      }

      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getUserProfile = createAsyncThunk('user/profile', async ({ enqueueSnackbar }) => {
  try {
    const response = await get(`/me`);
    if (response) {
      return response;
    }
    return enqueueSnackbar('something went wrong', { variant: 'error' });
  } catch (error) {
    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    throw error?.response?.data?.message;
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
