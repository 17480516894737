import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { updateUserProfile } from 'src/server/api/auth';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useGetRoles();

  const dispatch = useDispatch();
  const { account } = useSelector((state) => ({
    account: state.auth.user,
    loading: state.auth.loading,
  }));

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string(),
    phone: Yup.string()
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
  });

  const defaultValues = {
    name: account?.name || '',
    email: account?.email || '',
    phone: account?.phone || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    // setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: data,
      enqueueSnackbar,
    };
    dispatch(updateUserProfile(credentials));
  });

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('image', newFile, { shouldValidate: true });
  //     }
  //   },
  //   [setValue]
  // );

  // const [flagImagePreview, setFlagImagePreview] = useState([]);

  useEffect(() => {
    reset({
      name: account?.name,
      email: account?.email,
      phone: account?.phone,
    });
  }, [reset, account]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {/* <Grid xs={12} md={4}>
          <Card sx={{ pt: 2, pb: 2, px: 3, textAlign: 'center' }}>
            <FileUploadCustom
              uploadUrl="/stemxpert"
              setImagesPreview={setFlagImagePreview}
              imagesPreview={flagImagePreview}
              title="Upload Image"
              isMultiple
              // isLogo={true}
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'image',
                handleDrop,
              }}
            />
          </Card>
        </Grid> */}

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="name" label="Name" />
              <RHFTextField disabled required name="email" label="Email Address" />
              <RHFTextField
                disabled={role !== 'admin'}
                required
                name="phone"
                label="Phone Number"
              />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
