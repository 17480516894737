import { m } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';

import { useResponsive } from 'src/hooks/use-responsive';

import about from 'src/assets/other/About.png';

import Image from 'src/components/image';
import { varFade, MotionViewport } from 'src/components/animate';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Development', 'Design', 'Marketing'][index],
  value: [20, 40, 60][index],
}));

// ----------------------------------------------------------------------

export default function AboutCompany() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const lightMode = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    lightMode ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  const onDownloadPdf = () => {
    const pdfFilePath = '/assets/doc/FINV 0.2.pdf';

    const link = document.createElement('a');
    link.href = pdfFilePath;
    link.download = 'finV_catalog.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 5, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}
    >
      <Grid container columnSpacing={{ md: 3 }} alignItems="flex-start">
        <Grid xs={12} md={6} lg={6} sx={{ px: mdUp ? 10 : 1 }}>
          <m.div variants={varFade().inRight}>
            <Image alt="our office 1" src={about} sx={{ mb: 3 }} />
          </m.div>

          <m.div variants={varFade().inRight}>
            <Typography
              sx={{
                mb: 5,
                color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
                textAlign: 'start',
              }}
            >
              FINV is an LLP which is signed with ILLUMATE ENERGY INDIA(P) LTD to be a channel
              partner as C&F!
            </Typography>
          </m.div>

          <Stack
            sx={{ mt: 2 }}
            direction={mdUp ? 'row' : 'column'}
            alignItems="center"
            justifyContent="start"
          >
            <m.div variants={varFade().inRight} style={{ marginRight: mdUp ? 20 : 0 }}>
              <ScrollLink to="apply_share" smooth duration={1000} offset={-50}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 100,
                    mb: 2,
                    background: '#145072',
                    fontWeight: 400,
                  }}
                  color="info"
                  size="large"
                >
                  Apply For Shares
                </Button>
              </ScrollLink>
            </m.div>
            <m.div variants={varFade().inRight}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                sx={{ borderRadius: 100, mb: 2, color: '#145072', fontWeight: 400 }}
                startIcon={<CloudDownloadRoundedIcon />}
                onClick={onDownloadPdf}
              >
                Download Catalog
              </Button>
            </m.div>
          </Stack>
        </Grid>

        {mdUp && (
          <Grid container xs={12} md={6} lg={6} alignItems="center" sx={{ pr: { md: 7 } }}>
            <Grid xs={12}>
              <m.div variants={varFade().inUp} className="my_about_image">
                <Image
                  alt="our office 1"
                  src="/assets/images/about/what_1.png"
                  ratio="1/1"
                  sx={{
                    borderRadius: 6,
                    boxShadow: shadow,
                    width: 430,
                    height: 430,
                  }}
                />
              </m.div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
    // </Box>
  );
}
