import PropTypes from 'prop-types';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { LoginView } from 'src/sections/auth';

// ----------------------------------------------------------------------

export default function LoginAdminPage({ role }) {
  return (
    <>
      <MetaHelmet title="Login" />
      <LoginView role={role} />
    </>
  );
}
LoginAdminPage.propTypes = {
  role: PropTypes.string,
};
