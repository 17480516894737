import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
// import { KeyboardBackspace } from '@mui/icons-material';
// @mui
import { Box, Card, Stack, Alert, TextField } from '@mui/material';

import { colors } from 'src/theme/colors';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
// eslint-disable-next-line import/named

import { getUserDetails, updateUserDetails } from 'src/server/api/users';

// components

export default function PartnerDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const { loading, error, userDetails } = useSelector((state) => ({
    loading: state.users.loading,
    userDetails: state.users.userDetail,
    error: state.users.error,
  }));
  const Id = location.state;
  console.log(Id);
  console.log(error);

  useEffect(() => {
    const credentials = {
      userId: params?.id,
      dispatch,
    };
    dispatch(getUserDetails(credentials));
  }, [dispatch, params?.id]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        ...formData,
      },
      dispatch,
      userId: userDetails?._id,
      // navigate: isSaved === true ? navigate(paths.dashboard.partner) : '',
    };
    dispatch(updateUserDetails(credentials));
  };

  // const [values, setValues] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValues(newValue);
  // };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }
  // country

  // useEffect(() => {
  //   const credentials = {
  //     search: countrySearchText,
  //     dispatch,
  //   };
  //   dispatch(getAllSession(credentials));
  // }, [countrySearchText, dispatch]);

  return (
    <>
      <MetaHelmet title="partner" />
      {/* <Container sx={{ paddingLeft: '2rem', paddingRight: '0px' }}> */}
      <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
        {/* <Stack direction="row" alignItems="center" justifyContent="end" mb={5} sx={{ mt: '1rem' }}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Partner Details
              </Typography>
            </Stack>
          
          </Stack> */}
        {Object.keys(errors).length > 0 && (
          <Alert severity="error" sx={{ mb: 3 }}>
            <Typography variant="caption">
              Fill following required felids before submitting.
            </Typography>
            <Stack direction="row">
              {Object.keys(errors).map((fieldName) => (
                <Typography variant="caption" style={{ marginRight: 5 }} key={fieldName}>
                  {fieldName},
                </Typography>
              ))}
            </Stack>
          </Alert>
        )}

        <Card
          sx={{
            p: 3,
            width: '50%',
          }}
        >
          <Typography sx={{ p: 2 }}>Update User</Typography>
          <Box sx={{ width: '100%', paddingBottom: '25px', overflow: '' }}>
            <div className="step-wrapper">
              <Stack direction="row">
                <TextField
                  name="name"
                  label="Name "
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'name is required',
                    },
                    // required: { value: false, message: 'Phone is required' },
                    // maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                    // minLength: { value: 9, message: 'Phone number must be 9 characters' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 0.5 }}
                  size="small"
                  type="text"
                  defaultValue={userDetails?.name}
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginTop: '1rem' }}>
                <TextField
                  name="phone"
                  label="Phone "
                  {...register('phone', {
                    // required: {
                    //   value: false,
                    //   message: 'phone is required',
                    // },
                    required: { value: true, message: 'Phone is required' },
                    maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                    minLength: { value: 9, message: 'Phone number must be 9 characters' },
                  })}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  sx={{ width: '100%', mb: 0.5 }}
                  size="small"
                  type="number"
                  value={userDetails?.phone}
                />
                <TextField
                  name="email"
                  label="Email"
                  {...register('email', {
                    required: {
                      value: false,
                      message: 'email head is required',
                    },
                  })}
                  sx={{ width: '100%', mb: 0.5 }}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                  type="email"
                  value={userDetails?.email}
                />
              </Stack>
              {/* <Stack spacing={2} direction="row" sx={{ marginTop: '1rem' }}>
              <TextField
                name="password"
                label="Password"
                {...register('password', {
                  required: {
                    value: false,
                    message: 'password is required',
                  },
                })}
                error={!!errors.password}
                helperText={errors?.password?.message}
                sx={{ width: '100%', mb: 0.5 }}
                size="small"
                type="password"
              />
            </Stack> */}

              {/* <RHFTextField
                  name="password"
                  label="Password"
                  type={password.value ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password?.onToggle} edge="end">
                          <Iconify
                            icon={password?.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
                className=""
              >
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="medium"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Update
                </LoadingButton>
              </Box>
            </div>
          </Box>
        </Card>
      </form>
      {/* </Container> */}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
