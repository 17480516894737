import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';

import { NAV, HEADER } from '../config-layout';
import AccountPopover from '../common/account-popover';

// ----------------------------------------------------------------------

export default function PartnerHeader({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const changeTheme = (currentTheme) => {
    settings.onUpdate('themeMode', currentTheme);
  };

  const renderContent = (
    <>
      <Stack direction="row" alignItems="center">
        {/* {lgUp && <Logo sx={{ mr: 2.5 }} />}

        {!lgUp && (
          <IconButton onClick={onOpenNav}>
            <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
          </IconButton>
        )} */}
        <Logo sx={{ mr: 2.5 }} />
      </Stack>

      {/* <Searchbar /> */}
      <Stack
        direction="row"
        className="nav-stack"
        // spacing={lgUp ? 2 : 8}
        alignItems="center"
        flexGrow={1}
        justifyContent="center"
      >
        <Link to={paths.dashboard.root} style={{ textDecoration: 'none' }}>
          <Typography
            className="text-nav"
            sx={{ textDecoration: 'unset', color: 'black', fontWeight: '700' }}
            variant="body1"
          >
            Home
          </Typography>
        </Link>
        <Link to={`${paths.dashboard.profile}#general`} style={{ textDecoration: 'none' }}>
          <Typography className="text-nav" sx={{ color: 'black', fontWeight: '700' }}>
            Overview
          </Typography>
        </Link>
        <Link to={`${paths.dashboard.profile}#profile`} style={{ textDecoration: 'none' }}>
          <Typography className="text-nav" sx={{ color: 'black', fontWeight: '700' }}>
            Profile
          </Typography>
        </Link>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Stack
          flexGrow={0}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1 }}
        >
          {/* <LanguagePopover /> */}

          {/* <NotificationsPopover /> */}

          {/* <ContactsPopover /> */}

          {/* <SettingsButton /> */}

          <AccountPopover changeTheme={changeTheme} />
        </Stack>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: '100%',
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

PartnerHeader.propTypes = {
  onOpenNav: PropTypes.func,
};
