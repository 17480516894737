import propType from 'prop-types';
import { useSnackbar } from 'notistack';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Box, Grid, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { uploadPaymentScreenshot, getTransactionDetailsByShareholder } from 'src/server/api/shares';

import Image from 'src/components/image';

import UploadFilePopup from './uploadPopup';
// ----------------------------------------------------------------------

export default function Payment({ maxWidth, selectData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useResponsive('down', 'md');

  const { buyShareDetails, settings, uploadFile, transaction, screenshotUpload } = useSelector(
    (state) => ({
      buyShareDetails: state.share.buyShare,
      settings: state.settings.settingsData,
      uploadFile: state.common.file,
      transaction: state.share.transaction,
      screenshotUpload: state.share.screenshotUpload,
    })
  );

  const handleShareData = useCallback(() => {
    if (selectData) {
      return selectData;
    }
    return buyShareDetails;
  }, [buyShareDetails, selectData]);

  const renderHead = (
    <Stack spacing={0.5} sx={{ mb: 1.5 }}>
      <Typography
        variant="body2"
        sx={{ color: '#9EA0A3', maxWidth: mobile ? '100%' : '70%', textAlign: 'start' }}
      >
        Easily make payments using our secure methods: QR code or bank transfer. Choose the option
        that suits you best
      </Typography>
      <Typography
        variant="body2"
        sx={{ display: 'flex', alignItems: 'center', color: '#6B6B6B', justifyContent: 'start' }}
      >
        Pay&nbsp;&nbsp;
        <Typography variant="h5" sx={{ color: '#145072' }}>
          ₹ {handleShareData()?.total_amount}
        </Typography>
        &nbsp;&nbsp; ({handleShareData()?.share_phase?.single_share_amount}x
        {handleShareData()?.share_count})
      </Typography>
      {handleShareData()?.discount_amount > 0 && (
        <Typography variant="caption" color="green" sx={{ textAlign: 'start' }}>
          {' '}
          - ₹{handleShareData()?.discount_amount} off, thanks to our exclusive promo offer
        </Typography>
      )}
    </Stack>
  );

  const account_credentials = settings?.account_credentials;
  console.log(account_credentials);
  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
      shareholderId: handleShareData()?._id,
    };
    if (handleShareData()?._id !== undefined) {
      dispatch(getTransactionDetailsByShareholder(credentials));
    }
  }, [dispatch, enqueueSnackbar, handleShareData, screenshotUpload]);

  const renderForm = (
    <Grid
      container
      flexDirection={mobile ? 'column' : 'row'}
      maxWidth={maxWidth}
      spacing={2}
      alignItems="start"
      justifyContent=""
    >
      {account_credentials?.qr_code?.[0]?.url && (
        <Grid item>
          <Typography color="#01B0DC" variant="body1">
            Using QR Code
          </Typography>

          <Box sx={{ minHeight: '150px', mt: 1 }}>
            <Image
              alt="our office 1"
              src={account_credentials?.qr_code[0]?.url}
              ratio="1/1"
              sx={{
                width: 140,
                height: 140,
                objectFit: 'contain',
              }}
            />
          </Box>
        </Grid>
      )}
      <Grid item>
        <Typography color="#01B0DC" variant="body1">
          &nbsp;&nbsp;
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '150px',
            mx: 4,
          }}
        >
          <RemoveRoundedIcon fontSize="small" color="#6B6B6B" />
          or
          <RemoveRoundedIcon fontSize="small" color="#6B6B6B" />
        </Box>
      </Grid>
      <Grid item>
        <Typography color="#01B0DC" variant="body1">
          Using Bank Details
        </Typography>
        <Box sx={{ minHeight: '150px', mt: 2.5 }}>
          {account_credentials?.account_holder_name && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Account Holder Name :{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.account_holder_name}
              </Typography>
            </Typography>
          )}
          {account_credentials?.bank_name && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Bank Name:{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.bank_name}
              </Typography>
            </Typography>
          )}

          {account_credentials?.branch_name && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Branch Name :
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.branch_name}
              </Typography>
            </Typography>
          )}
          {account_credentials?.account_number && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Account Number :
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.account_number}
              </Typography>
            </Typography>
          )}
          {account_credentials?.ifsc_code && (
            <Typography variant="body2" display="flex" mb={1.5}>
              IFSC Code :
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.ifsc_code}
              </Typography>
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );

  const renderMobileQrForm = (
    <Grid
      container
      flexDirection="column"
      maxWidth={maxWidth}
      spacing={2}
      alignItems="start"
      justifyContent=""
    >
      {account_credentials?.qr_code?.url && (
        <Grid
          item
          sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Typography color="#01B0DC" variant="body1">
            Using QR Code
          </Typography>

          <Box sx={{ minHeight: '150px', mt: 1 }}>
            <Image
              alt="our office 1"
              src={account_credentials?.qr_code?.url}
              ratio="1/1"
              sx={{
                width: 140,
                height: 140,
                objectFit: 'contain',
              }}
            />
          </Box>
        </Grid>
      )}

      <Grid item sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: mobile ? '20px' : '150px',
            mx: 4,
          }}
        >
          <RemoveRoundedIcon fontSize="small" color="#6B6B6B" />
          or
          <RemoveRoundedIcon fontSize="small" color="#6B6B6B" />
        </Box>
      </Grid>

      <Grid
        item
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Typography color="#01B0DC" variant="body1" mb={1.3}>
          Using Bank Details
        </Typography>
        <Box sx={{ minHeight: '150px', mt: 1 }}>
          {account_credentials?.account_holder_name && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Account Holder Name :{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.account_holder_name}
              </Typography>
            </Typography>
          )}
          {account_credentials?.bank_name && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Bank Name :{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.bank_name}
              </Typography>
            </Typography>
          )}
          {account_credentials?.branch_name && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Branch Name:{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.branch_name}
              </Typography>
            </Typography>
          )}
          {account_credentials?.account_number && (
            <Typography variant="body2" display="flex" mb={1.5}>
              Account Number :
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.account_number}
              </Typography>
            </Typography>
          )}
          {account_credentials?.ifsc_code && (
            <Typography variant="body2" display="flex" mb={1.5}>
              IFSC Code :
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {account_credentials?.ifsc_code}
              </Typography>
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );

  const upload = useBoolean();
  console.log(uploadFile);
  useEffect(() => {
    const credentials = {
      state: {
        shareholder: handleShareData()?._id,
        pay_screenshot: uploadFile,
      },
      enqueueSnackbar,
      dispatch,
    };
    if (uploadFile?.url) {
      dispatch(uploadPaymentScreenshot(credentials));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);
  // const lastTransaction = transaction && transaction.length > 0 ? transaction[transaction.length - 1] : null;
  const lastTransaction = transaction?.length > 0 ? transaction[0] : null;
  console.log(lastTransaction);

  const uploadScreenShot = (
    <>
      <UploadFilePopup open={upload.value} onClose={upload.onFalse} title="Upload Screenshot" />

      <Button
        variant="contained"
        color="info"
        sx={{ borderRadius: '30px', my: 2, backgroundColor: '#145072', maxWidth: '200px' }}
        size="medium"
        onClick={upload.onTrue}
        // disabled={(transaction?.status === 'approved', 'pending')}
        disabled={
          transaction &&
          (lastTransaction?.status === 'approved' || lastTransaction?.status === 'pending')
        }
      >
        <Image
          alt="doc"
          src="/assets/images/about/doc.png"
          ratio="1/1"
          sx={{
            width: 20,
            height: 20,
            objectFit: 'contain',
            mr: 1,
          }}
        />
        Upload Screenshot
      </Button>

      {lastTransaction?.status === 'pending' && (
        <Typography variant="body2" maxWidth={600} color="green" textAlign="start">
          Your payment is currently under review, and it will be verified and approved by the
          authorized personnel within 48 hours.
        </Typography>
      )}
      {lastTransaction?.status === 'failed' && (
        <Typography variant="body2" maxWidth={600} color="red" textAlign="start">
          Your payment is currently under review and unfortunately, it has not been successful.
          Please take a moment to check the transaction details or consider making the payment
          again.
        </Typography>
      )}
      {lastTransaction?.status === 'approved' && (
        <Typography variant="body2" maxWidth={600} color="green" textAlign="start">
          Your payment verification has been successfully completed. You can now proceed to download
          the payment invoice.
        </Typography>
      )}
    </>
  );

  return (
    <Stack sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
      {renderHead}
      {mobile ? renderMobileQrForm : renderForm}
      {uploadScreenShot}
    </Stack>
  );
}

Payment.propTypes = {
  maxWidth: propType.number,
  selectData: propType.any,
};
