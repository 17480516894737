import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { alpha, useTheme } from '@mui/material/styles';

import Image from 'src/components/image';
import { varFade } from 'src/components/animate';
import Carousel, { useCarousel, CarouselDots } from 'src/components/carousel';

// ----------------------------------------------------------------------

export default function ShareOffer({ list, ...other }) {
  const carousel = useCarousel({
    speed: 800,
    autoplay: true,
    vertical: true,
    verticalSwiping: true,
    ...CarouselDots({
      sx: {
        right: 20,
        bottom: 20,
        position: 'absolute',
        color: 'primary.light',
      },
    }),
  });

  return (
    <Card {...other}>
      <Carousel {...carousel.carouselSettings}>
        {list.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Carousel>
    </Card>
  );
}

ShareOffer.propTypes = {
  list: PropTypes.array,
};

// ----------------------------------------------------------------------

function CarouselItem({ item }) {
  const theme = useTheme();

  const { coverUrl, name } = item;

  const renderImg = (
    <Image
      alt={name}
      src={coverUrl}
      overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 40%, ${
        theme.palette.grey[900]
      } 100%)`}
      sx={{
        width: '320px',
        objectFit: 'cover',
        height: { xs: 300, xl: 320 },
        borderRadius: '16px',
      }}
    />
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <CardContent
        sx={{
          left: 15,
          width: 1,
          bottom: 30,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <m.div variants={varFade().inRight} style={{ marginRight: 20 }}>
          <Link href="#apply_share">
            <Button
              color="info"
              size="large"
              variant="contained"
              sx={{ borderRadius: '30px', fontWeight: 500 }}
            >
              Buy Right Now
            </Button>
          </Link>
        </m.div>
      </CardContent>

      {renderImg}
    </Box>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.object,
};
