import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { Box, Grid, List, Stack, ListItem, Typography, ListItemText } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getSettingsData } from 'src/server/api/settings';

import Logo from 'src/components/logo';
// import { useDevice } from '../../hooks/useResponsive';
// import MobileFooter from './MobileFooter';

const Footer = () => {
  const mdUp = useResponsive('up', 'md');
  const mobile = useResponsive('down', 'md');
  const dispatch = useDispatch();

  const { settingsData } = useSelector((state) => ({
    settingsData: state.settings.settingsData,
  }));
  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsData(credentials));
  }, [dispatch]);
  console.log(settingsData);
  const footerCompanyItems = [
    {
      itemName: (
        <Typography variant="body2" lineHeight={2}>
          {/* Room No : Z53, 2nd Floor Daliya Keepees Avenue Uphill, Perinthalmanna road Malappuram,
          Kerala - 676505 */}

          {settingsData?.company?.address?.addressLine1 && (
            <>{settingsData.company.address.addressLine1}, </>
          )}
          {settingsData?.company?.address?.addressLine2 && (
            <>{settingsData.company.address.addressLine2}, </>
          )}
          {settingsData?.company?.address.country?.name && (
            <>{settingsData.company.address.country.name}, </>
          )}
          {settingsData?.company?.address.district?.name && (
            <>{settingsData.company.address.district.name} </>
          )}
        </Typography>
      ),
      link: '/',
    },
  ];

  const footerQuickItems = [
    {
      itemName: (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
        >
          <LocalPhoneRoundedIcon fontSize="small" sx={{ mr: 0.5 }} />{settingsData?.contact_details?.mobile }
        </Typography>
      ),
      link: '/',
    },
    {
      itemName: (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
        >
          <EmailRoundedIcon fontSize="small" sx={{ mr: 0.5 }} />
          {settingsData?.contact_details?.email }
        </Typography>
      ),
      link: '/',
    },
  ];

  return (
    <footer
      style={{
        backgroundColor: '#fff',
        padding: '20px 0',
        color: '#FFF',
        border: '1px solid #212121',
      }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Grid item className="">
              <Box
                width={mdUp ? '200px' : '100%'}
                alignContent={mdUp ? 'flex-start' : 'center'}
                display={mdUp ? 'block' : 'flex'}
                flexDirection={mdUp ? '' : 'column'}
                alignItems={mdUp ? '' : 'center'}
              >
                <Logo
                  sx={{
                    zIndex: 9,
                    m: { xs: 2, md: 5 },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} sx={{ marginTop: mdUp ? '2rem' : '0.5rem' }}>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0, lineHeight: '30px' }}>
              <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                <Typography
                  justifyContent={mdUp ? 'flex-start' : 'center'}
                  sx={{
                    display: 'flex',
                    paddingBottom: mobile ? '0' : '25px',
                    marginLeft: 2,
                    color: '#145072',
                    textTransform: 'capitalize',
                  }}
                >
                  Address
                </Typography>
                {footerCompanyItems?.map((item, key) => (
                  <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                    <ListItem
                      TextAlign={mdUp ? 'flex-start' : 'center'}
                      sx={{
                        display: 'flex',
                        textAlign: mdUp ? 'flex-start' : 'center',
                      }}
                    >
                      <ListItemText
                        justifyContent={mdUp ? 'flex-start' : 'center'}
                        sx={{ color: '#6B6B6B' }}
                        primary={item?.itemName}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} sx={{ marginTop: mdUp ? '2rem' : '0.5rem' }}>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0, lineHeight: '30px' }}>
              <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                <Typography
                  justifyContent={mdUp ? 'flex-start' : 'center'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: mobile ? '0' : '25px',

                    marginLeft: 2,
                    textTransform: 'capitalize',
                    color: '#145072',
                  }}
                >
                  Contact us
                </Typography>
                {footerQuickItems?.map((item, key) => (
                  <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                    <ListItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: mdUp ? 'flex-start' : 'center',
                      }}
                    >
                      <ListItemText
                        className={mobile ? 'text-align-center' : ''}
                        sx={{ color: '#A9A9A9' }}
                        primary={item?.itemName}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </ul>
          </Grid>

          <Grid item sx={{ marginTop: mdUp ? '3rem' : '0.5rem' }} xs={12} md={2}>
            {/* <Button
              variant="outlined"
              sx={{
                borderRadius: 100,
                mb: 2,
                background: '#145072',
                color: '#fff',
                fontWeight: 400,
                border: 'none',
              }}
              color="inherit"
              size="large"
            >
              Apply For Shares
            </Button> */}
          </Grid>
        </Grid>

        <Stack justifyContent="center" alignItems="center" mt={6}>
          <Typography className="footer-copyrights" variant="caption">
            <span style={{ color: '#767676' }}>© 2024</span>
            <span style={{ color: '#A9A9A9' }}> FinV</span>{' '}
            <span style={{ color: '#767676' }}>All rights reserved. </span>{' '}
           
          </Typography>
        </Stack>
      </Container>
    </footer>
  );
};

export default Footer;
