import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import ErrorIcon from '@mui/icons-material/Error';
import { Grid, Stack, Container, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllPurchasedShares } from 'src/server/api/shares';

import MySharesList from 'src/sections/partner/profile-shares';

import AnalyticsWidgetSummary from './analytics-widget-summary';
import ProfileTransaction from '../transaction/profile-transaction';

// ----------------------------------------------------------------------

export default function AccountOverView({ type }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const mobile = useResponsive('down', 'md');

  const { profile, myShares } = useSelector((state) => ({
    profile: state.users.profile,
    myShares: state.share.myShares,
    loading: state.users.loading,
  }));

  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
      page: '',
      search: '',
      limit: '',
      date: '',
      user: '',
      share_phase: '',
    };
    dispatch(getAllPurchasedShares(credentials));
  }, [dispatch, enqueueSnackbar]);

  const shareholderData = profile?.shareholderData?.shareholderData;

  return (
    <Container maxWidth="xl" sx={{ padding: mobile && 0 }}>
      <Grid container spacing={mobile ? 1 : 3}>
        <Grid item xs={6} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Purchased Shares"
            total={shareholderData?.totalPurchasedSharesCount || '0'}
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Approved Shares"
            total={shareholderData?.totalSharesApprovedCount || '0'}
            color="info"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Shares Pending"
            total={shareholderData?.totalSharesPendingCount || '0'}
            color="warning"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Purchased Amount"
            total={shareholderData?.totalPurchasedSharesAmount || '0'}
            color="error"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
          />
        </Grid>
      </Grid>

      <Stack sx={{ py: 2, pb: 0 }}>
        <Typography
          variant="caption"
          sx={{ display: 'flex', mb: 1, alignItems: 'center', justifyContent: 'start' }}
        >
          <ErrorIcon fontSize="small" color="info" sx={{ mr: 1 }} />
          Total Shares Approved Amount &nbsp;&nbsp;
          <Typography variant="subtitle2">
            ₹ {shareholderData?.totalSharesApprovedAmount}
          </Typography>
        </Typography>
        <Typography
          variant="caption"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
        >
          <ErrorIcon fontSize="small" color="info" sx={{ mr: 1 }} />
          Total Shares Pending Amount&nbsp;&nbsp;
          <Typography variant="subtitle2">₹ {shareholderData?.totalSharesPendingAmount}</Typography>
        </Typography>
      </Stack>
      {type === 'shares' ? <MySharesList shares={myShares} /> : <ProfileTransaction />}
    </Container>
  );
}

AccountOverView.propTypes = {
  type: PropTypes.any,
};
