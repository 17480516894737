import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        {/* <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }}>
            {user?.displayName?.charAt(0).toUpperCase()}
          </Avatar>

          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Free
          </Label>
        </Box> */}

        <Stack spacing={0} sx={{ mb: 2, mt: 1.5, width: 1 }}>
          {/* <Typography variant="subtitle2" noWrap sx={{ color: '#1881bb' }}>
            designed by osperb
          </Typography> */}

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            version 1.3.0
          </Typography>
        </Stack>

        {/* <Button variant="contained" href='/' target="_blank" rel="noopener">
          Upgrade to Pro
        </Button> */}
      </Stack>
    </Stack>
  );
}
