import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { loginApi } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// ----------------------------------------------------------------------

export default function LoginView({ role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error, loading } = useSelector((state) => ({
    error: state.auth.error,
    loading: state.auth.loginLoading,
  }));

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (loginFormData) => {
    const credentials = {
      state: loginFormData,
      dispatch,
      navigate,
    };
    dispatch(loginApi(credentials));
  });

  const renderHead = (
    <Stack spacing={0.5} sx={{ mb: 2 }}>
      <Typography variant="h4">{role} Login</Typography>
      <Typography sx={{ fontSize: '.7rem', color: '#9EA0A3' }}>
        Please provide your credentials
      </Typography>
      <Stack direction="row" spacing={0.5}>
        {/* <Typography variant="body2">Continue as a Administrator</Typography> */}
        {/* <Link component={RouterLink} href="/auth/register" variant="subtitle2">
          Create an account
        </Link> */}
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        href={`${paths.auth.forgot_password}`}
        variant="body2"
        underline="always"
        sx={{
          alignSelf: 'flex-end',
          color: '#767676',
          cursor: 'pointer',
          textDecorationColor: '#767676',
        }}
      >
        Forgot password?
      </Link>

      <LoadingButton
        sx={{ backgroundColor: '#F49935', color: 'white' }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
      >
        Login to Account
      </LoadingButton>
      {/* <Typography
        sx={{ fontWeight: '600', color: '#767676', fontSize: '14px', textAlign: 'center' }}
      >
        Don’t have an account? &nbsp;
        <Link
          href={`${paths.auth.register}`}
          variant="body2"
          underline="always"
          sx={{
            alignSelf: 'flex-end',
            color: '#00A76F',
            fontSize: '14px',
            cursor: 'pointer',
            fontWeight: '700',
            textDecorationColor: '#00A76F',
          }}
        >
          Register
        </Link>
      </Typography> */}
    </Stack>
  );

  return (
    <Stack sx={{ backgroundColor: 'white', padding: '3rem 2rem', borderRadius: '10px ' }}>
      {renderHead}

      {error?.message && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error?.message}
        </Alert>
      )}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
    </Stack>
  );
}
LoginView.propTypes = {
  role: PropTypes.string,
};
