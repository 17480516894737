import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import Settings from 'src/pages/Settings/settings';
import AuthLayout from 'src/layouts/auth/authLayout';
import LoginAdminPage from 'src/pages/Common/Auth/Login';
import LoginAdmin from 'src/assets/other/LoginAdmin.png';
import LogoutPage from 'src/pages/Common/Auth/LogoutPage';
import RegisterPage from 'src/pages/Common/Auth/Register';
import VerifyOtpPage from 'src/pages/Common/Auth/VerifyOtp';
import LoginPartnerPage from 'src/pages/Common/Auth/PartnerLogin';
import PartnerProfileView from 'src/pages/Admin/users/PartnerProfileView';

import { SplashScreen } from 'src/components/loading-screen';

const LandingPage = lazy(() => import('src/pages/Common/LandingWeb/LandingPage'));
const CompactLayout = lazy(() => import('src/layouts/compact'));
const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));
const SharePhasePage = lazy(() => import('src/pages/Admin/SharePhase/index'));
const UserPage = lazy(() => import('src/pages/Admin/users/index'));
const TransactionsListView = lazy(() => import('src/pages/transaction/index'));
const TransactionsPage = lazy(() => import('src/pages/Admin/Transactions/index'));
const ShareHolderPage = lazy(() => import('src/pages/Admin/ShareHolder/index'));
const ShareHolderView = lazy(() => import('src/pages/Admin/ShareHolder/Single-view'));



const publicRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ path: 'landing', element: <LandingPage /> }],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const admin = 'Admin';
const partner = 'Partner';

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: 'admin',
            element: (
              <AuthLayout
                image={LoginAdmin}
                title="Authenticate as an administrator"
                userRole="admin"
              >
                <LoginAdminPage role={admin} />
              </AuthLayout>
            ),
          },

          {
            path: 'partner',
            element: (
              <AuthLayout title="Authenticate as an partner" userRole="partner">
                <LoginPartnerPage role={partner} />
              </AuthLayout>
            ),
          },
        ],
      },
      {
        path: 'register',
        element: (
          <AuthLayout theme="black" title="Register as an partner" userRole="partner">
            <RegisterPage />
          </AuthLayout>
        ),
      },
      {
        path: 'verify',
        element: (
          <AuthLayout theme="black" title="Register as an partner" userRole="partner">
            <VerifyOtpPage />
          </AuthLayout>
        ),
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },

      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'transactions',
    element: <TransactionsListView />,
  },
  {
    path: 'transaction',
    element: <TransactionsPage />,
  },
  {
    path: 'users',
    element: <UserPage />,
  },
  {
    path: 'share-phases',
    element: <SharePhasePage />,
  },
  {
    path: 'share-holders',
    element: <ShareHolderPage />,
  },
  { path: 'share-holders/:id', element: <ShareHolderView /> },

  {
    path: 'settings',
    element: <Settings />,
  },

  { path: 'partner/:id', element: <PartnerProfileView /> },
];

// partner
const partnerRoutes = [
  {
    path: 'apply-share',
    element: 'apply share',
  },
];

export { authRoutes, adminRoutes, publicRoutes, partnerRoutes };
