import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import AuthGuard from 'src/auth/guard/auth-guard';
import DashboardLayout from 'src/layouts/dashboard';
import PartnerLayout from 'src/layouts/partner/intext';
import AccountView from 'src/pages/Common/Auth/profile';
import ProfileTransaction from 'src/pages/Common/Auth/transaction';
import DashboardAdminApp from 'src/pages/Common/Dashboard/DashboardAdminApp';
import DashboardPartnerApp from 'src/pages/Common/Dashboard/DashboardPartnerApp';

import { LoadingScreen } from 'src/components/loading-screen';

import { authRoutes, adminRoutes, publicRoutes, partnerRoutes } from './routes';

const Page404 = lazy(() => import('src/pages/Common/Static/404'));

// ----------------------------------------------------------------------

export default function Router() {
  const { role } = useGetRoles();

  const adminRouteContainer = [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAdminApp /> },
        { path: 'profile', element: <AccountView /> },

        ...adminRoutes,

        // other routes are listed here with their roles
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/landing" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const partnerRouteContainer = [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <PartnerLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </PartnerLayout>
        </AuthGuard>
      ),

      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardPartnerApp /> },
        { path: 'profile', element: <AccountView /> },
        { path: 'profile/transaction/:id', element: <ProfileTransaction /> },

        ...partnerRoutes,

        // other routes are listed here with their roles
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/landing" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'admin':
        return adminRouteContainer;
      case 'partner':
        return partnerRouteContainer;
      default:
        return partnerRouteContainer;
    }
  };

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },

    ...handleRouteByRoles(),
    ...publicRoutes,
    ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
