import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image, title, role, theme }) {
  // const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 580,
        px: { xs: 2, md: 10 },
        // pt: { xs: 15, md: 20 },
        // pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );
  const logo = (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
      <Grid item>
        <Logo
          sx={{
            zIndex: 9,
            // No need for absolute positioning here
            m: { xs: 2, md: 5 },
          }}
        />
      </Grid>
    </Grid>
  );
  // const renderSection = (
  //   <Stack flexGrow={0.5} spacing={5} alignItems="center" justifyContent="center">
  //     <Stack sx={{ justifyContent: 'center', mt: 25, paddingLeft: '8rem' }}>
  //       <Typography
  //         sx={{
  //           maxWidth: 400,
  //           fontWeight: '700',
  //           textAlign: 'left',
  //           color: theme || 'white',
  //           fontSize: '42px',
  //         }}
  //       >
  //         Hey, Welcome back!
  //       </Typography>
  //       <Typography
  //         variant="body1"
  //         sx={{ maxWidth: 360, textAlign: 'left', color: theme || 'white' }}
  //       >
  //         Greetings, esteemed Administrator! Your presence is pivotal in navigating the realm of our
  //         system. Please proceed by entering your designated credentials to unlock access to the
  //         administrative panel.
  //       </Typography>
  //     </Stack>
  //   </Stack>
  // );

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        // backgroundImage: `url(${image})`,
        // alignItems: 'center',
        // justifyContent: 'center',
        // Replace with the path to your image
        backgroundSize: 'cover', // Adjust as needed
        backgroundPosition: 'center',
      }}
    >
      <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>{logo}</Stack>
      <Stack
        component="main"
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        {/* {mdUp && renderSection} */}

        {renderContent}
      </Stack>
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  role: PropTypes.string,
};
