import PropTypes from 'prop-types';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import LoginPartnerView from 'src/sections/auth/login-partner-view';

// ------  ----------------------------------------------------------------

export default function LoginPartnerPage({ role }) {
  return (
    <>
      <MetaHelmet title="Login" />
      <LoginPartnerView role={role} />
    </>
  );
}
LoginPartnerPage.propTypes = {
  role: PropTypes.string,
};
