import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import logoDarkPng from 'src/assets/logo/logo_dark.png';
import logoWhitePng from 'src/assets/logo/logo_white.png';
import logoSmDarkPng from 'src/assets/logo/logo_sm_dark.png';
import logoSmWhitePng from 'src/assets/logo/logo_sm_white.png';

import Image from '../image';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, theme, ...other }, ref) => {
  const settings = useSettingsContext();
  const mobile = useResponsive('down', 'md');

  const logo = (
    <>
      {settings?.themeLayout === 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '60%',
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image alt="logo" src={logoSmDarkPng} />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 100,
            height: 30,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <Image objectFit="contain" alt="logo" src={logoDarkPng} />
        </Box>
      )}
    </>
  );

  const logoDark = (
    <>
      {settings?.themeLayout === 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '60%',
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image alt="logo" src={logoSmWhitePng} />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: mobile ? 80 : 130,
            height: mobile ? 25 : 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <Image objectFit="contain" alt="logo" src={logoWhitePng} />
        </Box>
      )}
    </>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <>
      {theme === 'light' ? (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          {logoDark}
        </Link>
      ) : (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          {settings.themeMode === 'light' ? logo : logoDark}
        </Link>
      )}
    </>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  theme: PropTypes.any,
  sx: PropTypes.object,
};

export default Logo;
